import {DataState} from "./reducers";
import {SET_PAGINATE, UPDATE_PAGINATE} from "./types";

export const PaginateReducer = (
    state: any = {},
    action: any,
): DataState => {
    switch (action.type) {
        case SET_PAGINATE:
            return {...state, [action.payload.storeName]: action.payload.payload}
        case UPDATE_PAGINATE:
            return {...state, [action.payload.storeName]: {...state[action.payload.storeName], ...action.payload.payload}}
        default:
            return state;
    }
};