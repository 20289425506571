import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'core/assets/scss/components/daterangepicker.scss';
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from 'date-fns';
import {useEffect, useRef, useState} from 'react';
import {change} from "redux-form";
import moment from "moment/moment";
const DateRangePicker2 = (props: any) => {
    console.log(props, 'propspropsprops')
    const [state, setState] = useState([
        {
            startDate: props.startDate ? moment(props.startDate).toDate() : new Date(),
            endDate: props.endDate ? moment(props.endDate).toDate() : new Date(),
            key: 'selection'
        }
    ]);

    // const [minDate, setMinDate] = useState();
    
    const [inputValue, setInputValue] = useState({
        startDate: props.startDate || "",
        endDate: props.endDate || ""
    })
    const inputRef = useRef(null);
    const divRef = useRef<HTMLDivElement>(null);
    const [panel, setPanel] = useState(false)


    useEffect(() => {
        console.log(29, props.meta)
        if(props.meta.initial?.startDate && props.meta.initial?.endDate){
            let data = {
                startDate: moment(props.meta.initial.startDate).format('YYYY-MM-DD'),
                endDate: moment(props.meta.initial.endDate).format('YYYY-MM-DD')
            }
            setInputValue(data)
            setState([{
                startDate: props.meta.initial.startDate ? moment(props.meta.initial.startDate).toDate() : new Date(),
                endDate: props.meta.initial.endDate ? moment(props.meta.initial.endDate).toDate() : new Date(),
                key: 'selection'
            }])
            props.handleDispatch(change(props.formName, props.input.name, data))
        }
        // else if(!props.meta.initial) {
        //     let data = {
        //         startDate: moment(new Date).format('YYYY-MM-DD'),
        //         endDate: moment(new Date).format('YYYY-MM-DD')
        //     }
        //     setInputValue(data)
        //     props.handleDispatch(change(props.formName, props.input.name, data))
        // }

    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // //console.log(event, divRef.current)
            if (divRef.current && 'contains' in divRef.current && !divRef.current.contains(event.target as Node)) {
                setPanel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputClick = () => {
        setPanel(true);
    };

    const handleChangeDate = (item: any) => {
        // setState([item.selection]);
        const { startDate, endDate } = item.selection;
        const maxEndDate = addDays(startDate, 90);
        // if (endDate > maxEndDate && props.meta.initial?.maxDate) {
        //     setState([
        //         {
        //             ...item.selection,
        //             endDate: maxEndDate,
        //         }
        //     ]);
        //     let data = {
        //         startDate: moment(startDate).format('YYYY-MM-DD'),
        //         endDate: moment(maxEndDate).format('YYYY-MM-DD')
        //     }
        //     setInputValue(data)
        //     props.handleDispatch(change(props.formName, props.input.name, data))
        // }else{
        //     setState([item.selection]);
        //     let data = {
        //         startDate: moment(startDate).format('YYYY-MM-DD'),
        //         endDate: moment(endDate).format('YYYY-MM-DD')
        //     }
        //     setInputValue(data)
        //     props.handleDispatch(change(props.formName, props.input.name, data))
        // }
        setState([item.selection]);
        let data = {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD')
        }
        setInputValue(data)
        props.handleDispatch(change(props.formName, props.input.name, data))
       
    }

    const handleClearDate = (e: any) => {
        let data = {
            startDate: '',
            endDate: ''
        }
        setInputValue(data)
        props.handleDispatch(change(props.formName, props.input.name, data))
        setState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ])
    }

    let maxDate: any;
    let minDate: any; 

    if (props.meta.initial?.maxDate) {
        maxDate = new Date();
        minDate = subDays(new Date(), props.meta.initial?.maxDate - 1); 
    }

    return <>
            <div className="custom-input date-range-picker">
                <label>{props.label}</label>
                <div className="date-range-picker-container">
                    <div className="date-ranger-filter">
                        <div className="input-wrap">
                            <input readOnly={true} ref={inputRef} name="startDate"  onClick={handleInputClick} value={inputValue.startDate}/>
                            {!inputValue.startDate ?
                                <i className="fa-regular fa-calendar"></i> :
                                <i className="fa-regular fa-calendar-xmark" onClick={handleClearDate}></i>}
                        </div>

                        <div className="division"></div>
                        <div className="input-wrap">
                            <input readOnly={true} ref={inputRef} name="endDate"  onClick={handleInputClick} value={inputValue.endDate}/>
                            {!inputValue.endDate ?
                                <i className="fa-regular fa-calendar"></i> :
                                <i className="fa-regular fa-calendar-xmark" onClick={handleClearDate}></i>}
                        </div>
                    </div>
                </div>
                <input {...props.input} disabled={props.disabled} readOnly={props.readOnly} type={props.type}/>
                {props.meta.error && <p className="error">{props.meta.error}</p>}
                {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
            </div>
        {panel &&
            <div className="picker-panel" ref={divRef}>
                <DateRangePicker
                    onChange={handleChangeDate}
                    moveRangeOnFirstSelection={true}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <div className="text-end">
                    <button className="btn btn-primary" onClick={()=>setPanel(false)}>Close</button>
                </div>
            </div>}
    </>
}

export default DateRangePicker2
