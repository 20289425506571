import KeywordComponent from "core/components/KeywordComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import { useCustomNavigate } from "core/utils/helper";
import {FormEvent, useEffect} from "react";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import DateRangePicker from "core/components/DateRangePicker";
import DateRangePicker2 from "core/components/DateRangePicker2";
import {updatePaginate} from "../../../../core/store/actions";

let formName = 'merchantMgmt'
interface CustomProps {

}
const Filter: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = (props: any) => {
    const navigate = useCustomNavigate()
    // //console.log(props)
    const { error, handleSubmit, pristine, reset, submitting, dispatch } = props

    const handleFilterSubmit = (e: any) => {
        e.preventDefault()
    }

    const handleSearch = (e: any) => {
        handleSubmit((values: any) => {
            props.onSubmit(values);
        })();
    }

    const handleGoToCreate = () => {
        navigate(RouterName.CONTENT_PAGE_CREATE)
    }

    useEffect(() => {
        //console.log(41, props.initialValues)
        dispatch(updatePaginate(props.filterName, {
            page_size: parseInt(props.initialValues.page_size || 20),
            page: parseInt(props.initialValues.page || 1)
        }))
    }, []);
    
    return <form onSubmit={handleFilterSubmit} className="mng-filter">
        <Field
            name="date"
            type="hidden"
            disabled={submitting}
            component={DateRangePicker2}
            label="Signup Date"
            handleDispatch={dispatch}
            formName={formName}
        />
        <Field
            name="keyword"
            type="hidden"
            disabled={submitting}
            component={KeywordComponent}
            label="Keyword"
            handleDispatch={dispatch}
            formName={formName}
        />
        <Field
            name="ex_keyword"
            type="hidden"
            disabled={submitting}
            component={KeywordComponent}
            label="Exclude Keyword"
            handleDispatch={dispatch}
            formName={formName}
        />
        <Field
            name="status"
            type="hidden"
            dataSet={[
                { value: '', label: 'All' },
                { value: 'INACTIVED', label: 'Inactive' },
                { value: 'ACTIVED', label: 'Active' },
            ]}
            defaultValue={{ value: '', label: 'All' }}
            isMulti={false}
            disabled={submitting}
            component={SelectComponent}
            label="Status"
            handleDispatch={dispatch}
            formName={formName}
        />

        {/*<Field name="page" type="hidden" component="input"/>*/}
        <button type="button" className="btn btn-search me-2 ms-5" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i> Search</button>
        {/*<button type="button" className="btn btn-create" onClick={handleGoToCreate}><i className="fa-solid fa-plus"></i> Thêm trang</button>*/}
    </form>
}


export default reduxForm<{}, CustomProps>({
    form: formName
})(Filter);