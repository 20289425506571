import DashboardLayout from "core/layouts/DashboardLayout"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {ReactNode, useEffect, useState} from "react";
import {fetchPawnDataList, initPawnDataList} from "../../store/actions";
import {parseFilterSelect} from "core/utils/helper";
import API from "core/networks/API";
import CategoryListRowTemplate from "./CategoryListRowTemplate";
import CategoryForm from "./CategoryForm";
import CategoryCreatePage from "./CategoryCreatePage";
import CategoryUpdatePage from "./CategoryUpdatePage";

let reduxFuncName = 'categoryMgmt'
const CategoryListPage = (props: any) => {
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])

    const parseFilter = () => {
        let filter = {...filterRedux}
        filter.status = parseFilterSelect(filter.status)
        //console.log({...filter, ...paginateRedux})
        return {...filter, ...paginateRedux, type: "POST"}
    }

    const [formAction, setFormAction] = useState("CREATE")
    const [formTitle, setFormTitle] = useState<ReactNode>(<>Tạo danh mục gốc</>)
    const [currentItem, setCurrentItem] = useState<any>(null)

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', '/cms/v1/content/category', parseFilter()))
    }

    const callDeleteData = async (ids: any) => {
        try{
            await API.delete("/cms/v1/content/category", {ids})
            callFetchData()
        } catch(e: any){
            console.error(e)
        }
    }

    const handleChangeFilter =(data: any) => {
        callFetchData()
    }

    const handleBulkDelete = (ids: any) => {
        //console.log(32, ids)
        callDeleteData(ids)
    }

    const resetToCreateRoot = () => {
        setFormAction("CREATE")
        setFormTitle(<>Thêm danh mục gốc</>)
        setCurrentItem(null)
    }

    const handleAddChild = (parent: any) => {
        //console.log(parent)
        setFormAction("CREATE")
        if(parent) {
            setFormTitle(<>Thêm danh mục con của <span className="label">{parent.name}</span> <em className="clickable" onClick={resetToCreateRoot}><i className="fa-solid fa-xmark"></i></em></>)
            setCurrentItem(parent)
        } else {
            setFormTitle(<>Thêm danh mục gốc</>)
            setCurrentItem(null)
        }
    }

    const handleEdit = (item: any) => {
        //console.log(item)
        setFormAction("UPDATE")
        if(item) {
            setFormTitle(<>Chỉnh sửa danh mục <span className="label">{item.name}</span> <em className="clickable" onClick={resetToCreateRoot}><i className="fa-solid fa-xmark"></i></em></>)
            setCurrentItem(item)
        } else {
            setFormTitle(<>Thêm danh mục gốc</>)
            setCurrentItem(null)
        }
    }

    useEffect(() => {
        if(paginateRedux) {
            callFetchData()
        }
    }, [paginateRedux]);

    useEffect(() => {
        dispatch(initPawnDataList(reduxFuncName))
    }, []);

    return <>
        <DashboardLayout currentPage="content/categories" title="Quản lý danh mục nội dung">
            <h1>Danh sách danh mục nội dung</h1>
            <div className="row">
                <div className="col-sm-5">
                    <div className="card mt-4">
                        <div className="card-body p-5">
                            {formAction === 'CREATE' ?
                                <CategoryCreatePage formTitle={formTitle} ObjId={currentItem?.id} onCreated={()=>callFetchData()}/> :
                                <CategoryUpdatePage formTitle={formTitle} initialValues={currentItem} onUpdated={()=>{}}/>
                            }
                        </div>
                    </div>

                </div>
                <div className="col-sm-7">
                    <DataSet
                        filterName={reduxFuncName}
                        columnKey={reduxFuncName}
                        pagination={{page: 1, page_size: 0}}
                        columnTemplate={[
                            // {label: "Trạng thái", key: "state", state: true, sort: false},
                            {label: "Tiêu đề", key: "name", state: true, sort: false},
                            // {label: "Người tạo", key: "created_by", state: true, sort: false},
                            // {label: "Tạo lúc", key: "created_at", state: true, sort: false},
                            // {label: "Người chỉnh sửa", key: "updated_by", state: true, sort: false},
                            // {label: "Ngày chỉnh sửa", key: "updated_at", state: true, sort: false},
                        ]}
                        onAdd={handleAddChild}
                        onEdit={handleEdit}
                        onDelete={handleBulkDelete}
                        rowTemplate={CategoryListRowTemplate}
                    />
                </div>
            </div>

        </DashboardLayout>
    </>
}

export default CategoryListPage