import { useEffect, useState, Fragment } from "react"
import { useParams } from "react-router-dom"
import MessageItem from './MessageItem'
import Loading from './Loading'
import useChatHeight from './../hooks/useChatHeight'
import { useMessage, useMessageParam } from './../hooks/useMessage'
import { useConversationDetail } from './../hooks/useConversation'

const NoData = ({message}: any) => (
  <div className="h-100 d-flex justify-content-center align-items-center">
    <div className="text-center">
      <i className="fa-regular fa-comment-alt fa-3x" />
      <div>{message}</div>
    </div>
  </div>
)

const groupedPosts = (sortedPosts: any) => {
  const posts = sortedPosts.reduce((grouped: any, post: any) => {
    if (grouped.length === 0) {
      grouped.unshift([post]);
    } else if (grouped[0][0].owner_message.account_id === post.owner_message.account_id) {
      grouped[0].push(post);
    } else {
      grouped.unshift([post]);
    }

    return grouped;
  }, []);

  return posts.reverse();
}

const MessageList = (props: any) => {
  const { param, selectMessage } = useMessageParam()
  const messages = useMessage(param)
  const height = useChatHeight()
  const { id } = useParams()
  const conversation = useConversationDetail()
  const user = Object.values(conversation.data)
    .find((e: any) => e.account_type === 'USER')
  const merchant = Object.values(conversation.data)
    .find((e: any) => e.account_type === 'MERCHANT')

  if (!id) {
    return <div
      id="message-list"
      style={{height: height + 'px', overflowY: "auto"}}
    >
      <NoData message="Please select the conversation." />
    </div>
  }

  return <div
    id="message-list"
    style={{height: height + 'px', overflowY: "auto"}}
  >
    {(param?.keyword && messages.data.length === 0)
      ? <NoData message="No data matching the request was found." />
      : null
    }
    {groupedPosts(messages.data)?.map((messageGroup: any) => (
      messageGroup.map((message: any, key: any) => (
        <div
          key={key}
          id={`message-${message.id}`}
          onClick={() => selectMessage(message)}
          className={`message-item${param?.keyword ? ' cursor-pointer border-bottom' : ''}`}>
          {(message.owner_message?.account_type === "USER"
            ? <div className="message message-user">
              <MessageItem
                message={message}
                user={key == 0 ? user : null}
                formName={props.formName}
                param={param}
              />
            </div> : <div className="message message-shop">
              <MessageItem
                message={message}
                user={key == 0 ? merchant : null}
                formName={props.formName}
                param={param}
              />
            </div>
          )}
        </div>
      ))
    ))}
    {messages.loading ? <Loading /> : null}
  </div>
}

export default MessageList
