import {FETCH_PAWN_LIST, FETCH_PAWN_LIST_FAIL, FETCH_PAWN_LIST_SUCCESS, INIT_PAWN_LIST, FETCH_USER_POST, FETCH_USER_POST_FAILED, FETCH_USER_POST_SUCCESS, FETCH_USER_DETAIL, FETCH_USER_DETAIL_SUCCESS, FETCH_USER_DETAIL_FAILED, FETCH_USER_COUPON, FETCH_USER_COUPON_SUCCESS, FETCH_USER_COUPON_FAILED, FETCH_USER_CHAT, FETCH_USER_CHAT_SUCCESS, FETCH_USER_CHAT_FAILED} from "./types";

export default {}

export const initPawnDataList = (storeName:string) => ({
    type: INIT_PAWN_LIST,
    storeName
})

export const fetchPawnDataList = (storeName:string, method: string, url:string, payload: any) => ({
    type: FETCH_PAWN_LIST,
    storeName, method, url, payload
})

export const fetchPawnDataListSuccess = (storeName:string, payload: any) => ({
    type: FETCH_PAWN_LIST_SUCCESS,
    storeName,
    payload
})

export const fetchPawnDataListFail = (storeName:string) => ({
    type: FETCH_PAWN_LIST_FAIL,
    storeName
})

export const fetchUserDetail = (storeName:string, method: string, url:string, payload: any) => ({
    type: FETCH_USER_DETAIL,
    storeName, method, url, payload
})
  
export const fetchUserDetailSuccess = (payload: any) => ({
    type: FETCH_USER_DETAIL_SUCCESS,
    payload
})
  
export const fetchUserDetailStatisticFailed = (payload: any) => ({
    type: FETCH_USER_DETAIL_FAILED,
    payload
})

export const fetchUserPost = (storeName:string, method: string, url:string, payload: any) => ({
    type: FETCH_USER_POST,
    storeName, method, url, payload
})
  
export const fetchUserPostSuccess = (payload: any) => ({
    type: FETCH_USER_POST_SUCCESS,
    payload
})
  
export const fetchUserPostStatisticFailed = (payload: any) => ({
    type: FETCH_USER_POST_FAILED,
    payload
})


export const fetchUserCoupon = (storeName:string, method: string, url:string, payload: any) => ({
    type: FETCH_USER_COUPON,
    storeName, method, url, payload
})
  
export const fetchUserCouponSuccess = (payload: any) => ({
    type: FETCH_USER_COUPON_SUCCESS,
    payload
})
  
export const fetchUserCouponStatisticFailed = (payload: any) => ({
    type: FETCH_USER_COUPON_FAILED,
    payload
})

export const fetchUserChat = (storeName:string, method: string, url:string, payload: any) => ({
    type: FETCH_USER_CHAT,
    storeName, method, url, payload
})
  
export const fetchUserChatSuccess = (payload: any) => ({
    type: FETCH_USER_CHAT_SUCCESS,
    payload
})
  
export const fetchUserChatStatisticFailed = (payload: any) => ({
    type: FETCH_USER_CHAT_FAILED,
    payload
})