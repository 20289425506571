import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  formatDateTime,
  getAreaInfo,
  useCustomNavigate,
} from "../../../core/utils/helper";

const PostStatisticListRowTemplate = (props: any) => {
  useEffect(() => {}, [props]);

  const renderCell = (key: any) => {
    switch (key) {
      case "id":
        return <>#{props.data.id}</>;
      case "name":
        return (
          <>
            [{props.data.category_name}]
            <br />
            {props.data.name}
          </>
        );
      case "address":
        return (
          <>
            {props.data?.address ? `${props.data?.address}, ` : ""}{" "}
            {props.data?.map_address
              ? props.data?.map_address
              : getAreaInfo(
                  undefined,
                  props.data?.city_id,
                  props.data?.district_id,
                  props.data?.ward_id
                )}
          </>
        );
      case "display_name":
        return (
          <>
            {props.data.display_name}
            <br />
            {props.data.phone}
          </>
        );
      case "total_bids":
        return <>{props.data.total_bids}</>;
      case "merchants":
        return <>{props.data?.merchants}</>;
      case "status":
        return <>{props.data.status}</>;
      case "created_at":
        return <>{formatDateTime(props.data.created_at)}</>;
      default:
        return <></>;
    }
  };

  const handlePreview = () => {
    window.open(`/${props.data?.slug?.alias}.html`, "_blank");
  };
  const handleEdit = () => {
    window.open(`https://api.pawnmarket.enjoyworks.co.kr/statistic`, "_blank");
  };
  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete([props.data.id]);
    }
  };

  return (
    <tr onDoubleClick={handleEdit}>
      {props.columns.length > 0 &&
        props.columns.map((col: any, k: number) => {
          if (col.state) return <td key={k}>{renderCell(col.key)}</td>;
        })}
    </tr>
  );
};
export default PostStatisticListRowTemplate;
