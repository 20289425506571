import SkeletonComponent from "core/components/Skeleton/skeleton";

interface StatisticWidgetsProps {
  dataWidgets: any;
}

const StatisticWidgets = ({ dataWidgets }: StatisticWidgetsProps) => {
  const { data, loading } = dataWidgets;
  return (
    <div className="row total-user mb-5">
      <div className="col-2 bg-white p-3 shadow-sm bg-body rounded ms-3">
        <div className="label p-1 fs-5">Lifetime Total Users</div>
        <div className="value d-flex justify-content-between">
          {loading ? (
            <SkeletonComponent width={70} height={30} />
          ) : (
            <h2 className="fw-bold fs-2">{data?.users || 0}</h2>
          )}
          <div className="icon ">
            <i className="fa-solid fa-users fa-3x"></i>
          </div>
        </div>
      </div>
      <div className="col-2 bg-white p-3 shadow-sm bg-body rounded ms-4">
        <div className="label p-1 fs-5">Lifetime Total Merchants</div>
        <div className="value d-flex justify-content-between">
          {loading ? (
            <SkeletonComponent width={70} height={30} />
          ) : (
            <h2 className="fw-bold fs-2">{data?.merchants || 0}</h2>
          )}
          <div className="icon ">
            <i className="fa-solid fa-store fa-3x"></i>
          </div>
        </div>
      </div>
      <div className="col-2 bg-white p-3 shadow-sm bg-body rounded ms-4">
        <div className="label p-1 fs-5">Lifetime Total Posts</div>
        <div className="value d-flex justify-content-between">
          {loading ? (
            <SkeletonComponent width={70} height={30} />
          ) : (
            <h2 className="fw-bold fs-2">{data?.properties || 0}</h2>
          )}
          <div className="icon ">
            <i className="fa-solid fa-bookmark fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticWidgets;
