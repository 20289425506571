import KDialog from "../Dialog/KDialog";
import {EditorImageDialogProps} from "./interfaces";
import {useState} from "react";
import ReactDOM from "react-dom";
import FileBrowserDialog from "core/components/FilePicker/FileBrowserDialog";

const EditorImageDialog = (props: EditorImageDialogProps) => {
    const [imageData, setImageData] = useState(props.data)

    const handleClose = () => {
        if(props.onClose) props.onClose()
    }

    const handleConfirm = () => {
        if(props.onConfirm) props.onConfirm(imageData)
    }

    const handleChangeInput = (e: any) => {
        setImageData({...imageData, [e.target.name]: e.target.value})
    }

    const handleOpenLibrary = () => {
        const portalContainer = document.createElement('div');
        portalContainer.id = 'FilePicker'
        document.body.appendChild(portalContainer)
        // @ts-ignore
        ReactDOM.render(
            <FileBrowserDialog onSelect={handleSelectFile} onClose={handleCloseLibrary}/>,
            portalContainer
        );
    }

    const handleCloseLibrary = () => {
        // @ts-ignore
        document.getElementById('FilePicker').remove()

    }

    const handleSelectFile = (data: any) => {
        setImageData({...imageData, src: data.url})
        // @ts-ignore
        document.getElementById('FilePicker').remove()

    }

    return <div className="k-editor-image-dialog">
        <KDialog
            variant={'info'}
            icon={<i className="fa-regular fa-image"></i>}
            title={"Cài đặt hình ảnh"}
            show={true}
            overlay={true}
            onCancel={handleClose}
            cancelBtn={"Đóng"}
            onSubmit={handleConfirm}
            submitBtn={"Xác nhận"}
        >
            <div className="row mb-3">
                <div className="col-sm-12">
                    <label>URL:</label>
                    <div className="input-group">
                        <input name={'src'} value={imageData?.src} onChange={handleChangeInput} type="text" className="form-control"/>
                        <button className="btn btn-outline-secondary" type="button" onClick={handleOpenLibrary}>Chọn ảnh</button>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-12">
                    <label>Chú thích:</label>
                    <input name={'alt'} value={imageData?.alt} onChange={handleChangeInput} type="text" className="form-control"/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <label>Độ rộng: </label>
                    <input name={'width'} value={imageData?.width} onChange={handleChangeInput} type="text" className="form-control"/>
                </div>
                <div className="col-sm-3">
                    <label>Chiều cao: </label>
                    <input name={'height'} value={imageData?.height} onChange={handleChangeInput} type="text" className="form-control"/>
                </div>
                <div className="col-sm-3">
                    <label>Class: </label>
                    <input name={'class'} value={imageData?.class} onChange={handleChangeInput} type="text" className="form-control"/>
                </div>
                <div className="col-sm-3">
                    <label>ID: </label>
                    <input name={'id'} value={imageData?.id} onChange={handleChangeInput} type="text" className="form-control"/>
                </div>
            </div>
        </KDialog>
    </div>
}

export default EditorImageDialog