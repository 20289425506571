import { useBackNavigate, useCustomNavigate } from "core/utils/helper"
import MessageAttachments from './MessageAttachments'
import MessageBody from './MessageBody'
import { formatDateTime, getImageWithName } from "core/utils/helper";

const MessageShop = ({ message, user, formName, param }: any) => {
  const isMerchant = message.owner_message?.account_type === "MERCHANT"
  const userName = isMerchant
    ? user?.shops[0].name
    : user?.name

  return <>
    {user ? <div className="info">
      <div className="avatar">
        <img src={user?.avatar?.url?.medium || getImageWithName(userName)}/>
      </div>
      <h5>{userName} - {user?.phone}</h5>
    </div> : null}
    <div className={`d-flex flex-column${isMerchant ? ' align-items-end' : ''}`}>
      <div className="message-content">
        <MessageBody message={message} formName={formName} param={param}/>
        <MessageAttachments attachments={message.attachments}/>
      </div>
      <div className="message-time">
        {formatDateTime(message.created_at)}
      </div>
    </div>

  </>
}

export default MessageShop
