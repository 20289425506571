import {useDispatch, useSelector} from "react-redux";
import {formatDateTime, numberFormat, parseFilterSelect, useBackNavigate, useCustomNavigate} from "core/utils/helper";
import {fetchPawnDataList} from "../../store/actions";
import {API_BULKPN_DETAIL, API_BULKPN_DETAIL_DEVICE, API_COUPON_HISTORIES} from "../../api";
import {useEffect, useState} from "react";
import DashboardLayout from "core/layouts/DashboardLayout";
import BulkPNCampaignFilter from "./BulkPNCampaignFilter";
import DataSet from "core/components/DataSet";
import BulkPNCampaignRowTemplate from "./BulkPNCampaignRowTemplate";
import {RouterName} from "core/router";
import BulkPNDeviceRowTemplate from "./BulkPNDeviceRowTemplate";
import {useParams} from "react-router-dom";
import API from "../../../../core/networks/API";

let reduxFuncName = 'bulkPnDeviceMgmt'

const APP_TYPE: any = {"ALL": "All", "USER": "User App", "MERCHANT": "Merchant App"};

const PUSH_TYPE: any = {"UPDATE": "Update notify", "EVENT": "Event", "OTHER": "Other"};

const BulkPNCampaignDetailPage = () => {
    const dispatch = useDispatch()
    const params: any = useParams()
    const navigate = useCustomNavigate()
    const navigateBack = useBackNavigate()

    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])

    const handleGoBack = () => {
        navigateBack()
    }

    const [campaignDetail, setCampaignDetail] = useState<any>(null);

    const requestApiFetchCampaignDetail = async()=>{
        try{
            const res = await API.get(API_BULKPN_DETAIL.replace(/:id/, params.id), {})
            //console.log(res)
            if(res.data?.data){
                setCampaignDetail(res.data.data)
            }
        } catch (e) {
            console.error(e)
        }
    }


    const parseFilter = () => {
        let filter = {...filterRedux}
        //console.log({...filter, ...paginateRedux})
        return {...filter, ...paginateRedux}
    }
    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_BULKPN_DETAIL_DEVICE.replace(/:id/g, params.id), parseFilter()))
    }

    useEffect(()=>{
        requestApiFetchCampaignDetail()
    }, [])

    useEffect(() => {
        if(paginateRedux) {
            callFetchData()
        }
    }, [paginateRedux]);

    return <>
        <DashboardLayout currentPage="dashboard/marketing/bulk-pn" title="Bulk Push Notification">
            {campaignDetail ? <>
            <h1><span className="go-back" onClick={handleGoBack}><i
                className="fa-solid fa-chevron-left"></i> Quay lại</span> DETAIL CAMPAIGN - BULK PUSH NOTIFICATION</h1>
            <div className="card my-4">
                <div className="card-body">
                    <table className="table table-bordered table-form">
                        <tr>
                            <th style={{width: "200px"}}>Campaign name</th>
                            <td>{campaignDetail.name}</td>
                            <th style={{width: "200px"}}>App type</th>
                            <td>{APP_TYPE[campaignDetail.filter_app_type]}</td>
                            <th style={{width: "150px"}}>Total targets</th>
                            <td>{numberFormat(campaignDetail.total_recipients)}</td>
                        </tr>
                        <tr>
                            <th>Push type</th>
                            <td>{PUSH_TYPE[campaignDetail.type]}</td>
                            <th>Phone contains</th>
                            <td>{campaignDetail.filter_phone || ""}</td>
                            <th>Sents</th>
                            <td>{numberFormat(campaignDetail.total_sent)}</td>
                        </tr>
                        <tr>
                            <th>Action</th>
                            <td>{campaignDetail.action || "NONE"}</td>
                            <th>Region</th>
                            <td>Vietnam</td>
                            <th>Success</th>
                            <td>{numberFormat(campaignDetail.total_success)}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{formatDateTime(campaignDetail.created_at)}</td>
                            <th>Created by</th>
                            <td>{campaignDetail.created_by.username}</td>
                            <th>Failure</th>
                            <td>{numberFormat(campaignDetail.total_failure)}</td>
                        </tr>
                        <tr>
                            <th>Vietnamese content</th>
                            <td colSpan={5}>{campaignDetail.content.vi}</td>
                        </tr>
                        <tr>
                            <th>English content</th>
                            <td colSpan={5}>{campaignDetail.content.en}</td>
                        </tr>
                        <tr>
                            <th>Korean content</th>
                            <td colSpan={5}>{campaignDetail.content.ko}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="card my-4">
                <div className="card-body">
                    <DataSet
                        filterName={reduxFuncName}
                        module={'Pawn'}
                        idKey={'hidden'}
                        columnKey={reduxFuncName}
                        pagination={{page: 1, page_size: 20}}
                        columnTemplate={[
                            {label: "Sent at", key: "created_at", state: true, sort: false},
                            {label: "App type", key: "app_type", state: true, sort: false},
                            {label: "Account name", key: "acc_name", state: true, sort: false},
                            {label: "Language", key: "lang", state: true, sort: false},
                            {label: "Device platform", key: "device_platform", state: true, sort: false},
                            {label: "Device argent", key: "device_argent", state: true, sort: false},
                            {label: "Status", key: "status", state: true, sort: false},
                        ]}
                        onEdit={()=>{}}
                        onDelete={()=>{}}
                        rowTemplate={BulkPNDeviceRowTemplate}
                    />
                </div>
            </div>
            </> : <>
                <h1><span className="go-back" onClick={handleGoBack}><i
                    className="fa-solid fa-chevron-left"></i> Quay lại</span></h1>
                <div className="card">
                    <div className="card-body">
                        Campaign was not found.
                    </div>
                </div>
            </>}
        </DashboardLayout>
    </>
}

export default BulkPNCampaignDetailPage;
