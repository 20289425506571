import {useEffect} from "react";
import {config} from "core/config/app";

interface FileLibraryProps {
    onSelect: Function;
}

const FileLibrary = (props: FileLibraryProps) => {
    useEffect(() => {
        const messageListener = (e: any) => {
            // //console.log(e)
            if (e.origin === config.fileManagerUrl) {
                try {
                    const data = JSON.parse(e.data);

                    // Loại bỏ event listener sau khi đã gọi props.onSelect
                    window.removeEventListener('message', messageListener);

                    props.onSelect(data);
                } catch (e) {
                    //console.log(e)
                }
            }
        };

        // Thêm event listener
        window.addEventListener('message', messageListener);

        return () => {
            // Loại bỏ event listener khi component bị hủy
            window.removeEventListener('message', messageListener);
        };
    }, [props.onSelect]);

    return (
        <div className="kfilelib">
            <iframe src={`${config.fileManagerUrl}/api/filemanager?type=Images&ORIGINAL=${window.location.origin}`} />
        </div>
    );
}

export default FileLibrary