import ReactPaginate from "react-paginate"

const Pagination = (props: any) => {

    const handleChangePage = (e: any) => {
        // //console.log(e)
        props.onPageChange(e.selected + 1)
    }

    return <>
            <div className={props.className}>
                <ReactPaginate 
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={props.total || 0}
                    forcePage={props.current || 0}
                    onPageChange={handleChangePage}
                />
            </div>
    </>
}

export default Pagination
