import {useEffect, useState} from "react";

import RemoveIcon from "core/assets/images/icon/remove.svg"
import ReactDOM from "react-dom";
import FileLibrary from "./FileLibrary";
import FileBrowserDialog from "./FileBrowserDialog";
import ImageComponent from "../ImageComponent";

interface FilePickerProps {
    name: string,
    src: string,
    width?: string,
    height?: string,
    readonly?: boolean,
    disabled?: boolean,
    onChange?: Function,
    objectFit?: string,
}

const FilePicker = (props: FilePickerProps) => {
    const [src, setSrc] = useState<string>(props.src)
    const handleOpenLibrary = () => {
        const portalContainer = document.createElement('div');
        portalContainer.id = 'FilePicker'
        document.body.appendChild(portalContainer)
        // @ts-ignore
        ReactDOM.render(
            <FileBrowserDialog overlay={true} onSelect={handleSelectFile} onClose={handleCloseLibrary}/>,
            portalContainer
        );
    }

    const handleCloseLibrary = () => {
        // @ts-ignore
        document.getElementById('FilePicker').remove()
        // @ts-ignore
        document.getElementById('overlay').style.display = 'none'
    }

    const handleSelectFile = (data: any) => {
        setSrc(data.url)
        // @ts-ignore
        document.getElementById('FilePicker').remove()
        // @ts-ignore
        document.getElementById('overlay').style.display = 'none'

        if(props.onChange) props.onChange({target: {name: props.name, value: data.url}})
    }

    const handleChangeInput = (e:any) => {
        setSrc(e.target.value)
        //console.log(e.target)
        if(props.onChange) props.onChange({target: {name: props.name, value: e.target.value}})
    }

    const handleRemoveSrc = () => {
        setSrc("")
        if(props.onChange) props.onChange({target: {name: props.name, value: ""}})
    }

    useEffect(() => {
        if(props.src !== src) {
            //console.log(60, props.src)
            setSrc(props.src)
        }
    }, [props.src]);


    return <>
        <div className="k-file-picker">
            <div className="k-file-input">
                <input onChange={handleChangeInput} readOnly={props.readonly} disabled={props.disabled} type="text" value={src}/>
                <button disabled={props.disabled} type="button" onClick={handleOpenLibrary}>Chọn file</button>
            </div>
            {src && <div className="k-image-preview" style={{width: props.width || '100%', height: props.height || '100%'}}>
                <div className="preview">
                    <ImageComponent src={src}
                         title={""}
                         className={props.objectFit || 'contain'}/>
                </div>
                <div className="remove" onClick={handleRemoveSrc}>
                    <img src={RemoveIcon} alt={""}/>
                </div>
            </div>
            }
        </div>
    </>
}

export default FilePicker


