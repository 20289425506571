import DashboardLayout from "../../../core/layouts/DashboardLayout";
import "../assets/scss/style.scss"
import {
  useChartStatistic,
  useMerchantStatistic,
  usePostStatistic,
  useStatistic,
  useStatisticParam,
  useTotalStatistic,
  useUserStatistic
} from "../hooks/useStatistics";

import { useQuery } from "core/hooks/useQuery";
import { setParamsUrl } from "core/utils/helper";
import { useEffect } from "react";
import MerchantStatisticTable from "../components/MerchantStatisticTable";
import OverviewStatistic from "../components/OverviewStatistic";
import PostStatisticTable from "../components/PostStatisticTable";
import StatisticChart from "../components/StatisticChart";
import StatisticFilter from "../components/StatisticFilter";
import StatisticWidgets from "../components/StatisticWidgets";
import UserStatisticTable from "../components/UserStatisticTable";
import moment from "moment";
import { addDays } from "date-fns";

const Dashboard = (props: any) => {
  const [statistic, handleFetchStatistic] = useStatistic({});
  const [totalStatistic, handleFetchTotalStatistic] = useTotalStatistic({});
  const [chartStatistic, handleFetchChartStatistic] = useChartStatistic({});
  const [userStatistic, handleFetchUserStatistic] = useUserStatistic({});
  const [merchantStatistic, handleFetchMerchantStatistic] =
    useMerchantStatistic({});
  const [postStatistic, handleFetchPostStatistic] = usePostStatistic({});
  const queryParams = useQuery();
  const { param: statisticParam, handlePageChange: handlePageChange } =
    useStatisticParam(queryParams);

  useEffect(() => {
    handleFetchStatistic({});
  }, []);

  useEffect(() => {
    setParamsUrl(statisticParam);
  }, [statisticParam])

  useEffect(() => {
    handleFetchChartStatistic(statisticParam);
  }, [statisticParam.startDate, statisticParam.endDate, statisticParam.chart_option]);

  useEffect(() => {
    handleFetchTotalStatistic(statisticParam);
  }, [statisticParam.startDate, statisticParam.endDate]);

  useEffect(() => {
    handleFetchMerchantStatistic(statisticParam);
  }, [statisticParam.startDate, statisticParam.endDate, +statisticParam.m_page, +statisticParam.m_page_size]);

  useEffect(() => {
    handleFetchUserStatistic(statisticParam);
  }, [statisticParam.startDate, statisticParam.endDate, +statisticParam.u_page, +statisticParam.u_page_size]);

  useEffect(() => {
    handleFetchPostStatistic(statisticParam);
  }, [statisticParam.startDate, statisticParam.endDate, +statisticParam.p_page, +statisticParam.p_page_size]);

  const _startDate: any = new Date(statisticParam.startDate);
  const _endDate: any = new Date(statisticParam.endDate);
  const timeDifference = (_endDate - _startDate)/(1000 * 60 * 60 * 24) > 7;
  
  return (
    <>
      <DashboardLayout currentPage="dashboard/overview" title="Dashboard">
        <div className="overview-container">
          <StatisticWidgets dataWidgets={statistic} />
          <hr />
          <div className="row content-statics">

            <div className={timeDifference ? 'col-12' : 'col-4'}>
              <div className="row mb-3 pt-2">
                <div className="col-sm-12">
                  <StatisticFilter
                    initialValues={{
                      date: {
                        startDate: statisticParam.startDate,
                        endDate: statisticParam.endDate,
                        maxDate: 90
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3 pt-2">
                <div className="col-sm-12">
                  <OverviewStatistic dataOverview={totalStatistic} />
                </div>
              </div>
              <div className="row mb-3 pt-2 chart">
                <div className="col-sm-12">
                  <StatisticChart
                    chartData={chartStatistic}
                    queryParams={queryParams}
                    statisticParam={statisticParam}
                    initialValues={{
                      chart_option: statisticParam.chart_option,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={timeDifference ? 'col-12' : 'col-8'}>
              <UserStatisticTable queryParams={queryParams} userStatistic={userStatistic}/>
              <MerchantStatisticTable queryParams={queryParams} merchantStatistic={merchantStatistic}/>
              <PostStatisticTable queryParams={queryParams} postStatistic={postStatistic}/>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
