import { useEffect, useRef } from "react"
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { change } from "redux-form";
import EditorFileBrowser from "./EditorFileBrowser";

const RTEComponent = (props: any) => {
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const handleChange = (e: any) => {
        if (editorRef.current) {
            //console.log(editorRef.current.getContent());
            props.handleDispatch(change(props.formName, props.input.name, editorRef.current.getContent()))
        }
    }
    
    useEffect(()=>{
        // //console.log(5, props)
    }, [props])

    return <>
        <div className="custom-input">
            <label>{props.label}</label>
            {/* <input {...props.input} disabled={props.disabled} readOnly={props.readOnly} type={props.type} /> */}
            <Editor
                apiKey="ipmga29k3ycoix6y9wzcfloyg8macjgcudqs7zu65xdcv56a"
                onInit={(evt, editor) => editorRef.current = editor}
                onChange={handleChange}
                disabled={props.disabled}
                initialValue={props.input.value}
                init={{
                    height: 400,
                    menubar: false,
                    plugins: `preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link template codesample table charmap pagebreak nonbreaking anchor advlist lists wordcount help charmap emoticons accordion`,
                    toolbar: `undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | 
                    link editor-image-browser| table | lineheight outdent indent| forecolor backcolor removeformat |  code fullscreen `,
                    toolbar_mode: 'wrap',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    setup: (editor) => {
                        EditorFileBrowser(editor); // Thêm plugin tùy chỉnh
                    },
                }}

            />
            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default RTEComponent