import InputComponent from "core/components/InputComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import { useCustomNavigate } from "core/utils/helper";
import { updatePaginate } from "core/store/actions";
import { FormEvent, Fragment, useState, useEffect } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import DateRangePicker from "core/components/DateRangePicker";
import DateRangePicker2 from "core/components/DateRangePicker2";
import KeywordComponent from "core/components/KeywordComponent";
import KDialog from "../../../../core/components/Dialog/KDialog";
import useDebounce from "../../../../core/hooks/useDebounce";
import {parseFilterSelect} from "../../../../core/utils/helper";
import {
  API_BULK_PN,
  API_PRE_COUNT_RECIPIENT,
} from "../../api";
import API from "../../../../core/networks/API";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";

let formName = "bulkPushMgmt";
interface CustomProps {
  onCreate: Function;
  filterName: string;
}
const FormCreateCampaign: React.FC<
  CustomProps & InjectedFormProps<{}, CustomProps>
> = (props: any) => {
  const navigate = useCustomNavigate();
  // //console.log(props)
  const { error, handleSubmit, pristine, reset, submitting, dispatch } = props;

  const [isConfirmCreate, setConfirmCreateDialog] = useState(false)
  const [isCancelDialog, setCancelDialog] = useState(false)
  const [issueError, setIssueError] = useState<any>({})
  const [totalTecipients, setTotalRecipients] = useState<any>(0)
  const [loadingTotalTecipients, setLoadingTotalRecipients] = useState<any>(false)
  const valuesRedux = useSelector((state: any) => state.form[formName]?.values)
  let filter_app_type_db = useDebounce(parseFilterSelect(valuesRedux.filter_app_type), 100)
  let filter_phone_db = useDebounce(valuesRedux.filter_phone, 100)
  let filter_region_db = useDebounce(parseFilterSelect(valuesRedux.filter_region), 100)

  const validateForm = (data: any) => {
    let errors: any = {}
    let flag = false
    console.log(data, '1111')
    if (!data.name) {
      errors.name = 'The name field is required.'
      flag = true
    }
    if (!data.type) {
      errors.type = 'The type field is required.'
      flag = true
    }
    if (!data.content_vi) {
      errors.content_vi = 'The content vi field is required.'
      flag = true
    }
    if (!data.content_en) {
      errors.content_en = 'The content en field is required.'
      flag = true
    }
    if (!data.content_ko) {
      errors.content_ko = 'The content ko field is required.'
      flag = true
    }
    if (!data.filter_app_type) {
      errors.filter_app_type = 'The filter app type field is required.'
      flag = true
    }

    return {
      flag,
      errors
    }
  }

  const callCreateBulk = async (data: any) => {
    try{
      const res = await API.post(API_BULK_PN, data)

      toast.success("Create Campaign successfuly")
      setConfirmCreateDialog(false)
      navigate(RouterName.MKT_BULK_PN_LIST)
    } catch (e: any) {
      setConfirmCreateDialog(false)
      if([422].includes(e.response.status)){
        let er: any = {}
        e.response.data.errors.map((e: any) => {
          er[e.field] = e.error
        })
        setIssueError(er)
      } else{
        toast.error("Create Campaign failed, undefined error")
      }
    }
  }

  const callPreCountingRecipients = async (data: any) => {
      setLoadingTotalRecipients(true)
    try{
      const res = await API.get(API_PRE_COUNT_RECIPIENT, data)
      console.log(res, "resresres")
      setTotalRecipients(res.data.data)
      setLoadingTotalRecipients(false)

    } catch (e: any) {
      console.log(e)
      setLoadingTotalRecipients(false)
    }
  }

  const getFormData = (values: any) => {
    console.log(values, parseFilterSelect(values.type), 'reeee')
    values.type = parseFilterSelect(values.type)
    values.action = parseFilterSelect(values.action)
    values.filter_app_type = parseFilterSelect(values.filter_app_type)
    values.filter_region = parseFilterSelect(values.filter_region)
    values.filter_app_type = values.filter_app_type || ''
    values.filter_region = values.filter_region || '1'
    values.total_recipients = totalTecipients || '0'

    return values

  }

  const _handleSubmit = async (e: any) => {
    if (!props.submitting) {
      await props.handleSubmit(async (values: any) => {
        values = getFormData(values)
        await callCreateBulk(values);
      })();
    }
  };

  useEffect(() => {
    if (filter_app_type_db) {
      callPreCountingRecipients({
        filter_app_type: filter_app_type_db || 'ALL',
        filter_phone: filter_phone_db || '',
        filter_region: filter_region_db || '1',
      })
    }
  }, [filter_app_type_db, filter_phone_db, filter_region_db])

  const handleCancle = (e: any) => {
    console.log('cancel')
    setCancelDialog(true)
  };

  const handleConfirmCreate = () => {
    let validate = validateForm(getFormData(valuesRedux))
    setIssueError({})
    if (validate.flag) {
      setIssueError(validate.errors)
      return
    } else {
      setConfirmCreateDialog(true)
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleConfirmCreate} className="form-filter ">
        <div className="row align-items-stretch ">
          <div className="col-sm-4">
            <div className="card h-100">
              <h5 className="m-3">Infomation</h5>
              <div className="card-body">

                <div className="row">

                  <div className="col-12">
                    <Field
                      name="name"
                      type="text"
                      disabled={submitting}
                      component={InputComponent}
                      label="Campaign name"
                      handleDispatch={dispatch}
                      formName={formName}
                      required
                      max={150}
                    />
                    {issueError.name && <p className="text-danger">{issueError.name}</p>}
                    <Field
                      name="type"
                      type="hidden"
                      dataSet={[
                        // { value: "", label: "Select type" },
                        { value: "UPDATE", label: "Update notify" },
                        { value: "EVENT", label: "Event" },
                        { value: "OTHER", label: "Other" },
                      ]}
                      defaultValue={{ value: "", label: "Select type" }}
                      isMulti={false}
                      disabled={submitting}
                      component={SelectComponent}
                      label="Push type"
                      handleDispatch={dispatch}
                      formName={formName}
                      required
                    />
                    {issueError.type && <p className="text-danger">{issueError.type}</p>}
                    <Field
                      name="action"
                      type="hidden"
                      dataSet={[
                        { value: "NONE", label: "NONE" },
                      ]}
                      defaultValue={{ value: "NONE", label: "NONE" }}
                      isMulti={false}
                      disabled={submitting}
                      component={SelectComponent}
                      label="Action"
                      handleDispatch={dispatch}
                      formName={formName}
                      required
                    />
                    {issueError.action && <p className="text-danger">{issueError.action}</p>}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="mb-4">
              <div className="card">
                <h5 className="m-3">Target</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      <Field
                        name="filter_app_type"
                        type="hidden"
                        dataSet={[
                          { value: "ALL", label: "All" },
                          { value: "USER", label: "User App" },
                          { value: "MERCHANT", label: "Merchant App" },
                        ]}
                        defaultValue={{ value: "ALL", label: "All" }}
                        isMulti={false}
                        disabled={submitting}
                        component={SelectComponent}
                        label="App type"
                        handleDispatch={dispatch}
                        formName={formName}
                        required
                      />
                      {issueError.filter_app_type && <p className="text-danger">{issueError.filter_app_type}</p>}
                      <Field
                        name="filter_phone"
                        type="hidden"
                        disabled={submitting}
                        component={KeywordComponent}
                        label="Phone number"
                        handleDispatch={dispatch}
                        formName={formName}
                      />
                      {issueError.filter_phone && <p className="text-danger">{issueError.filter_phone}</p>}

                      <Field
                        name="filter_region"
                        type="hidden"
                        dataSet={[
                          { value: "1", label: "Viet Nam" },
                          { value: "2", label: "Korean" },
                        ]}
                        defaultValue={{ value: "1", label: "Viet Nam" }}
                        isMulti={false}
                        disabled={submitting}
                        component={SelectComponent}
                        label="Region"
                        handleDispatch={dispatch}
                        formName={formName}
                      />
                      {issueError.filter_region && <p className="text-danger">{issueError.filter_region}</p>}
                    </div>
                    <div className="col-3">
                      <p className="mt-3">Es total target : <strong>{loadingTotalTecipients ? '...' : totalTecipients}</strong> devices</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card">
                <h5 className="m-3">Content</h5>
                <div className="card-body">

                  <div className="row">
                    <div className="col-12">
                      <Field
                        name="content_vi"
                        type="text"
                        disabled={submitting}
                        component={InputComponent}
                        label="Vietnamese"
                        handleDispatch={dispatch}
                        formName={formName}
                        required
                        max={150}
                      />
                      {issueError.content_vi && <p className="text-danger">{issueError.content_vi}</p>}
                      <Field
                        name="content_en"
                        type="text"
                        disabled={submitting}
                        component={InputComponent}
                        label="English"
                        handleDispatch={dispatch}
                        formName={formName}
                        required
                        max={150}
                      />
                      {issueError.content_en && <p className="text-danger">{issueError.content_en}</p>}

                      <Field
                        name="content_ko"
                        type="text"
                        disabled={submitting}
                        component={InputComponent}
                        label="Korean"
                        handleDispatch={dispatch}
                        formName={formName}
                        required
                        max={150}
                      />
                      {issueError.content_ko && <p className="text-danger">{issueError.content_ko}</p>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="mb-2">

          </div>
        </div>
        <div className="mt-3">
          {/*<Field name="page" type="hidden" component="input"/>*/}
          <div className="d-flex flex-column h-100 justify-content-end">
            <div className="mt-auto mb-1 text-right" style={{ textAlign: 'right' }}>
              <button
                type="button"
                className="btn btn-search me-2"
                onClick={handleCancle}
              >Cancel
              </button>
              <button
                type="button"
                className="btn btn-create"
                onClick={handleConfirmCreate}
              >Submit
              </button>
            </div>
          </div>
        </div>
      </form>



      <KDialog
          variant={'info'}
          icon={<i className="fa-solid fa-circle-exclamation"></i>}
          title={"Alert"}
          show={isConfirmCreate}
          overlay={true}
          onCancel={() => {
            setConfirmCreateDialog(false)
          }}
          cancelBtn={"No, I will check again"}
          onSubmit={_handleSubmit}
          submitBtn={`Yes, I am sure${props.submitting ? '...' : ''}`}
      >
          
        <div>
          {`Are you sure that you want to send a push notification to ${totalTecipients} recipients? This action cannot be undone.`}
        </div>
      </KDialog>

      <KDialog
          variant={'info'}
          icon={<i className="fa-solid fa-circle-exclamation"></i>}
          title={"Alert"}
          show={isCancelDialog}
          overlay={true}
          onCancel={() => {
              setCancelDialog(false)
          }}
          cancelBtn={"No, continue sending push"}
          onSubmit={() =>{
              navigate(RouterName.MKT_BULK_PN_LIST)
          }}
          submitBtn={"Yes, I am sure"}
      >
          
          <div>
             Do you want to cancel this operation?
          </div>
      </KDialog>
    </Fragment>
  );
};

export default reduxForm<{}, CustomProps>({
  form: formName,
})(FormCreateCampaign);
