import {FETCH_CONTENT_LIST, FETCH_CONTENT_LIST_FAIL, FETCH_CONTENT_LIST_SUCCESS, INIT_CONTENT_LIST} from "./types";


const ContentReducer = (
    state: any = {},
    action: any,
): any => {
    switch (action.type) {
        case INIT_CONTENT_LIST:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        case FETCH_CONTENT_LIST:
            return {...state, [action.storeName]: {list: {}, loading: true}}
        case FETCH_CONTENT_LIST_SUCCESS:
            return {...state, [action.storeName]: {list: action.payload, loading: false}}
        case FETCH_CONTENT_LIST_FAIL:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        default:
            return state;
    }
};

export default ContentReducer