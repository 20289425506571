// eslint-disable-next-line
export const config = {
  // prefix for all route
  appPackage: "cms",
  baseName: process.env.REACT_APP_BASENAME || '',
  apiUrl: process.env.REACT_APP_BASEAPI,
  version: process.env.REACT_APP_VERSION || '1.0.0',
  apiVersion: process.env.REACT_APP_API_VERSION || '1.0.0',
  debug: process.env.REACT_APP_DEBUG === "TRUE" || process.env.REACT_APP_DEBUG === true,
  s3Url: process.env.REACT_APP_AWS_BASE_URL,
  buildId: "20230929001",
  appStorage: process.env.REACT_APP_STORAGE || "session",
  webviewVersion: process.env.REACT_APP_WEBVIEW_VERSION || "1.0.0",
  mqttBrokerUrl: "ws://13.251.176.34:8083/mqtt",
  fileManagerUrl: process.env.REACT_APP_FILEBROWSER_URL || window._REACT_APP_FILEBROWSER_URL,
}