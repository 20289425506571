export const requestFetchConversation = (payload: any) => ({
    type: "REQUEST_FETCH_CONVERSATION",
    payload
})

export const requestFetchConversationSuccess = (payload: any) => ({
    type: "REQUEST_FETCH_CONVERSATION_SUCCESS",
    payload
})

export const requestFetchConversationFail = (payload: any) => ({
    type: "REQUEST_FETCH_CONVERSATION_FAIL",
    payload
})

export const requestFetchMessage = (payload: any) => ({
    type: "REQUEST_FETCH_MESSAGE",
    payload
})

export const requestFetchMessageSuccess = (payload: any) => ({
    type: "REQUEST_FETCH_MESSAGE_SUCCESS",
    payload
})

export const requestFetchMessageFail = (payload: any) => ({
    type: "REQUEST_FETCH_MESSAGE_FAIL",
    payload
})

export const requestFetchConversationDetail = (payload: any) => ({
    type: "REQUEST_FETCH_CONVERSATION_DETAIL",
    payload
})

export const requestFetchConversationDetailSuccess = (payload: any) => ({
    type: "REQUEST_FETCH_CONVERSATION_DETAIL_SUCCESS",
    payload
})

export const requestFetchConversationDetailFail = (payload: any) => ({
    type: "REQUEST_FETCH_CONVERSATION_DETAIL_FAIL",
    payload
})
