import {Editor} from "tinymce";
import ReactDOM from "react-dom";
import EditorImageDialog from "./EditorImageDialog";
import {EditorImageDataType} from "./interfaces";

const EditorFileBrowser = (editor: Editor) => {


    const handleOpenDialog = (imageData: EditorImageDataType) => {
        const portalContainer = document.createElement('div');
        portalContainer.id = 'EditorFileBrowser'
        document.body.appendChild(portalContainer)
        // @ts-ignore
        ReactDOM.render(
            <EditorImageDialog onClose={handleCloseDialog} data={imageData} onConfirm={handleConfirmDialog}/>,
            portalContainer
        );
    }

    const handleCloseDialog = () => {
        // @ts-ignore
        document.getElementById('EditorFileBrowser').remove()
        // @ts-ignore
        document.getElementById('overlay').style.display = 'none'
    }

    const handleConfirmDialog = (imageData: EditorImageDataType) => {
        editor.insertContent(`<img ${imageData.src && `src="${imageData.src}"`} ${imageData.id && `id="${imageData.id}"`} ${imageData.class && `class="${imageData.class}"`} ${imageData.width && `width="${imageData.width}"`} ${imageData.height && `height="${imageData.height}"`} ${imageData.alt && `alt="${imageData.alt}"`}/>`)
        handleCloseDialog()
    }

    editor.ui.registry.addButton('editor-image-browser', {
        // text: '',
        icon: 'image',
        onAction: () => {
            const imageData: EditorImageDataType = {
                alt: "",
                class: "",
                height: "",
                id: "",
                src: "",
                width: ""
            }
            handleOpenDialog(imageData)
        },
    });

    editor.on('init', () => {
        editor.dom.bind(editor.getBody(), 'dblclick', (e) => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'IMG') {
                const imageData: EditorImageDataType = {
                    alt: target.getAttribute("alt") || "",
                    class: target.getAttribute("class") || "",
                    height: target.getAttribute("height") || "",
                    id: target.getAttribute("id") || "",
                    src: target.getAttribute("src") || "",
                    width: target.getAttribute("width") || ""
                }
                handleOpenDialog(imageData)
            }
        });
        // editor.insertContent('<img src="https://www.tiny.cloud/docs-3x/images/for-base/logo-spaces-docs-for-base.png" data-custom-action="openPicker" />');
    });
}

export default EditorFileBrowser