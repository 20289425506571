import {useEffect} from "react";
import moment from "moment";
import {formatDateTime, highlightKeywords, useCustomNavigate} from "../../../../core/utils/helper";
import {RouterName} from "../../../../core/router";
import {useSelector} from "react-redux";

const MerchantListRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    const filterRedux = useSelector((state: any) => state.form['merchantMgmt']?.values)
    //console.log(filterRedux)
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderCell = (key: any) => {
        switch (key) {
            case 'title':
                return <><h5>{props.data[key]}</h5><em className="slug">/{props.data.slug.alias}.html</em> </>
            case 'created_at':
            case 'updated_at':
                return <>{formatDateTime(props.data[key])}</>
            case 'mc_state':
                return <>{props.data[key] === 'INACTIVED' ? <span className="badge rounded-pill bg-danger">Inactive</span> : <span className="badge rounded-pill bg-success">Active</span>}</>
            default:
                return <><div dangerouslySetInnerHTML={{__html: highlightKeywords(props.data[key], filterRedux?.keyword)}}></div></>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        navigate(RouterName.MERCHANT_DETAIL.replace(/:id/g, props.data.shop_id))
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        <td width={150}>
            <label>
                <input type={"checkbox"} value={props.data.shop_id} checked={props.checked} onChange={props.onCheck}/> #{props.data.shop_id}
            </label>
        </td>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
        <td className="text-end">
            <div className="actions">
                {/*<a onClick={handlePreview}><i className="fa-solid fa-eye"></i> <span>Xem</span></a>*/}
                {/*<a onClick={handleEdit}><i className="fa-solid fa-pen-to-square"></i> <span>Edit</span></a>*/}
                {/*<a onClick={handleDelete}><i className="fa-solid fa-trash-can"></i> <span>Re</span></a>*/}
            </div>
        </td>
    </tr>
}
export default MerchantListRowTemplate