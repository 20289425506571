import { documentTitle, useCustomNavigate } from "core/utils/helper"
import { useEffect, useState } from "react"

import '../assets/scss/login.scss'
import logo from 'core/assets/images/poanlogo.svg'
import InputComponent from "../../../core/components/InputComponent"
import { Field, SubmissionError, reduxForm } from "redux-form"
import API from "core/networks/API"
import { useDispatch, useSelector } from "react-redux"
import { requestLogin, requestLoginSuccess } from "../store/actions"
import Loading from "react-loading"
import { RouterName } from "core/router"


let formName = "loginForm"

const Login = (props: any) => {
    const reduxDispatch = useDispatch()
    const redux = useSelector((state: any) => state.Auth)
    const navigate = useCustomNavigate()
    const { error, handleSubmit, pristine, reset, submitting, dispatch } = props
    const [loginAlert, setLoginAlert] = useState("")




    const handleLogin = (formData: any) => {
        //console.log(13, formData)
        
        // simulate server latency
        if (!formData.username) {
            throw new SubmissionError({username: "Please input the username and password"})
        } else if (!formData.password) {
            throw new SubmissionError({password: "Please input the username and password"})
        } else {
            reduxDispatch(requestLogin(formData))
        }
    }

    useEffect(()=>{
        documentTitle("Login Poan Admin Dashboard")
    }, [])

    useEffect(()=>{
        if(["REQUEST_LOGIN_SUCCESS", "REQUEST_FETCH_AUTH_SUCCESS"].includes(redux.type)){ 
            const searchParams = new URLSearchParams(window.location.search);
            navigate((searchParams.has('referer') && searchParams.get('referer') != '/login') ? searchParams.get('referer') : RouterName.DASHBOARD)
        }
        if(["REQUEST_LOGIN_FAIL"].includes(redux.type)){
            setLoginAlert("Login failed! Please check your account and password.")
        }
    }, [redux])

    return <>
        <div className="login-page">
            <div className="login-panel">
                <form onSubmit={handleSubmit(handleLogin)}>
                    <img className="logo" src={logo} alt="" />
                    {/*<h1>POAN ADMIN DASHBOARD</h1>*/}
                    {loginAlert && <div className="mb-3 mt-5">
                        <div className="alert alert-danger">{loginAlert}</div>
                    </div>}
                    <div className="mb-3 mt-5">
                        <Field
                            name="username"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Username"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="password"
                            type="password"
                            disabled={submitting}
                            component={InputComponent}
                            label="Password"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>

                    <div className="d-grid mb-3">
                        <button className="btn btn-lg btn-primary">
                            <div className="d-flex justify-content-center">
                                {redux.loading && <Loading type="spin" height={24} width={24} className="me-3"/>}
                                Sign in
                            </div>
                        </button>
                    </div>
                </form>
            </div>
            <div className="login-intro"></div>
        </div>
    </>
}

export default reduxForm({
    form: formName // a unique identifier for this form
  })(Login)