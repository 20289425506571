import { useCallback } from 'react';
import {
  useBeforeUnload as _useBeforeUnload,
  unstable_usePrompt as usePrompt
} from 'react-router-dom';

export default function useBeforeUnload(doBlock?: boolean) {
  _useBeforeUnload(
    useCallback(e => {
      if (doBlock) {
        e.preventDefault();
        return e.returnValue = '';
      }
    }, [doBlock])
  );

  usePrompt({
    when: doBlock,
    message: 'The conversation content is being downloaded.\nDo you want to cancel and leave the page?'
  });
}
