import { useEffect, useState, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  requestFetchConversation,
  requestFetchConversationDetail,
} from "../store/actions"
import { updatePaginate } from "core/store/actions";
import { parseFilterSelect } from "core/utils/helper";
import { change } from "redux-form";

export const formName = 'conversationMgmt'

export const useConversationParam = () => {
  const filterRedux = useSelector((state: any) => state.form[formName]?.values)
  const paginateRedux = useSelector((state: any) => state.paginate[formName])
  const reduxDispatch = useDispatch()
  const [param, setParam] = useState({
    query_field: ''
  })

  useEffect(() => {
    if (paginateRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        page: paginateRedux?.page || 1,
        page_size: paginateRedux?.page_size || 20,
      }))
    }
  }, [paginateRedux])

  useEffect(() => {
    if (filterRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        query_field: parseFilterSelect(filterRedux?.query_field) || '',
        query_value: filterRedux?.query_value,
        start_date: filterRedux?.date?.startDate,
        end_date: filterRedux?.date?.endDate,
        page: 1,
      }))
    }
  }, [filterRedux])

  // clear query_value when query_field change
  useEffect(() => {
    reduxDispatch(change(formName, 'query_value', ''))
  }, [filterRedux?.query_field])

  const handlePageChange = (page: number) => {
    reduxDispatch(updatePaginate(formName, {page}))
  }

  return {
    param,
    handlePageChange,
  }
}

export const useConversation = (param: any) => {
  const reduxDispatch = useDispatch()
  const redux = useSelector((state: any) => state.Chat)

  useEffect(() => {
    if (param) {
      reduxDispatch(requestFetchConversation(param))
    }
  }, [param])

  return redux.conversations
}

export const useConversationDetail = () => {
  const { id } = useParams()
  const reduxDispatch = useDispatch()
  const redux = useSelector((state: any) => state.Chat)

  useEffect(() => {
    if (id) {
      reduxDispatch(requestFetchConversationDetail(id))
    }
  }, [id])

  return redux.conversation
}
