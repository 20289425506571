import { config } from "core/config/app";
import ChatPage from "./pages/ChatPage";
import DownloadChatPage from "./pages/DownloadChatPage";
import './assets/scss/style.scss'

const router =  [
    {
        name: "CHAT_PAGE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/chat/:id?`,
        element: <ChatPage />
    },
    {
        name: "DOWNLOAD_CHAT_PAGE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/chat/:id/download`,
        element: <DownloadChatPage />
    },
]
export default router
