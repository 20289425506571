import {config} from "../../core/config/app";

export const API_ADMIN_MERCHANT = `/admin/${config.apiVersion}/merchants`
export const API_ADMIN_MERCHANT_STATISTIC = `/admin/${config.apiVersion}/merchants/list-statistic`


export const API_ISSUE_COUPON = `/admin/${config.apiVersion}/coupon/issue`
export const API_COUPON_LIST = `/admin/${config.apiVersion}/coupon`
export const API_COUPON_HISTORIES = `/admin/${config.apiVersion}/coupon/issue-histories`
export const API_COUPON_HISTORIES_STATISTIC = `/admin/${config.apiVersion}/coupon/issue-histories-statistic`
export const API_COUPON_RECONCILIATION = `/admin/${config.apiVersion}/coupon/reconciliation`
export const API_COUPON_RECONCILIATION_STATISTIC = `/admin/${config.apiVersion}/coupon/reconciliation-statistic`
export const API_COUPON_RECONCILIATION_SETTLEMENT = `/admin/${config.apiVersion}/coupon/settlement`

export const API_PULK_PN_LIST = `/admin/${config.apiVersion}/marketing/bulk-pn/lists`

export const API_BULKPN_DETAIL = `/admin/${config.apiVersion}/marketing/bulk-pn/:id`
export const API_BULKPN_DETAIL_DEVICE = `/admin/${config.apiVersion}/marketing/bulk-pn/:id/device-history`

export const API_BULK_PN = `/admin/${config.apiVersion}/marketing/bulk-pn`
export const API_PRE_COUNT_RECIPIENT = `/admin/${config.apiVersion}/marketing/bulk-pn/pre-count-recipients`
