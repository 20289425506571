import { change } from "redux-form"
import FilePicker from "./FilePicker"

const FilePickerComponent = (props: any) => {

    const handleChange = (e: any) => {
        // //console.log(6, e)
        props.handleDispatch(change(props.formName, props.input.name, e.target.value))
    }

    return <>
        <div className={"custom-input"}>
            <label>{props.label} {props.required && <span className="required">*</span>}</label>
            <FilePicker 
                name={'featured_image'} src={props.input.value} disabled={props.disabled} width={props.width} height={props.height} objectFit={props.objectFit}
                onChange={handleChange}
            />
            
            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default FilePickerComponent