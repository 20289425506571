import Lottie from "lottie-react";
import Loading from "react-loading";
import loadingFile from "core/assets/lotties/loading1.json"
const Splash = () => {
    return <>
        <div className="splash-screen">
            {/* <Loading type="spinningBubbles" color="#3D9700"/> */}
            <div className="loading">
                <Lottie animationData={loadingFile} loop={true} />
            </div>
        </div>
    </>
}

export default Splash