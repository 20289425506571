import DashboardLayout from "core/layouts/DashboardLayout"
import PageFilter from "./PageFilter"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchPawnDataList, initPawnDataList} from "../../store/actions";
import {parseFilterSelect} from "core/utils/helper";
import PageListRowTemplate from "./PageListRowTemplate";
import API from "../../../../core/networks/API";

let reduxFuncName = 'pageMgmt'
const PageListPage = (props: any) => {
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])

    const parseFilter = () => {
        let filter = {...filterRedux}
        filter.status = parseFilterSelect(filter.status)
        //console.log({...filter, ...paginateRedux})
        return {...filter, ...paginateRedux}
    }

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', '/cms/v1/content/page', parseFilter()))
    }

    const callDeleteData = async (ids: any) => {
        try{
            await API.delete("/cms/v1/content/page", {ids})
            callFetchData()
        } catch(e: any){
            console.error(e)
        }
    }

    const handleChangeFilter =(data: any) => {
        callFetchData()
    }

    const handleBulkDelete = (ids: any) => {
        //console.log(32, ids)
        callDeleteData(ids)
    }

    useEffect(() => {
        if(paginateRedux) {
            callFetchData()
        }
    }, [paginateRedux]);

    useEffect(() => {
        dispatch(initPawnDataList(reduxFuncName))
    }, []);

    return <>
        <DashboardLayout currentPage="content/page" title="Quản lý trang nội dung">
            <h1>Danh sách trang nội dung</h1>
            <PageFilter
                        onSubmit={handleChangeFilter}
                        initialValues={{
                            status: "",
                            keyword: ""
                        }}/>
            <DataSet
                filterName={reduxFuncName}
                columnKey={reduxFuncName}
                pagination={{page: 1, page_size: 20}}
                columnTemplate={[
                    {label: "Trạng thái", key: "state", state: true, sort: false},
                    {label: "Tiêu đề", key: "title", state: true, sort: false},
                    // {label: "Người tạo", key: "created_by", state: true, sort: false},
                    {label: "Tạo lúc", key: "created_at", state: true, sort: false},
                    // {label: "Người chỉnh sửa", key: "updated_by", state: true, sort: false},
                    {label: "Ngày chỉnh sửa", key: "updated_at", state: true, sort: false},
                ]}
                onDelete={handleBulkDelete}
                rowTemplate={PageListRowTemplate}
            />
        </DashboardLayout>
    </>
}

export default PageListPage