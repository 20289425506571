import API from "core/networks/API";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestFetchChartStatisticFailed,
  requestFetchChartStatisticSuccess,
  requestFetchMerchantStatisticFailed,
  requestFetchMerchantStatisticSuccess,
  requestFetchPostStatisticFailed,
  requestFetchPostStatisticSuccess,
  requestFetchStatisticFailed,
  requestFetchStatisticSuccess,
  requestFetchTotalStatisticFailed,
  requestFetchTotalStatisticSuccess,
  requestFetchUserStatisticFailed,
  requestFetchUserStatisticSuccess,
} from "./actions";
import { API_ADMIN_CHART_STATISTIC, API_ADMIN_MERCHANT_STATISTIC, API_ADMIN_POST_STATISTIC, API_ADMIN_STATISTIC, API_ADMIN_USERS_STATISTIC } from "../api";

function* fetchStatistic(_action: any): any {
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_STATISTIC, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put(requestFetchStatisticSuccess(data?.data || []));
    } else {
      yield put(requestFetchStatisticFailed(data));
    }
  } catch (error: any) {
    yield put(requestFetchStatisticFailed(error.response?.data));
  }
}

function* fetchTotalStatistic(_action: any): any {
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_STATISTIC, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put(requestFetchTotalStatisticSuccess(data?.data || []));
    } else {
      yield put(requestFetchTotalStatisticFailed(data));
    }
  } catch (error: any) {
    yield put(requestFetchTotalStatisticFailed(error.response?.data));
  }
}

function* fetchChartStatistic(_action: any): any {
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_CHART_STATISTIC, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put(requestFetchChartStatisticSuccess(data?.data || []));
    } else {
      yield put(requestFetchChartStatisticFailed(data));
    }
  } catch (error: any) {
    yield put(requestFetchChartStatisticFailed(error.response?.data));
  }
}

function* fetchUserStatistic(_action: any): any {
  console.log('fetchUserStatistic', _action.payload);
  
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_USERS_STATISTIC, _action.payload);
    });
    const { data } = response;

    if (data?.code === 200) {
      yield put(requestFetchUserStatisticSuccess(data.data));
    } else {
      yield put(requestFetchUserStatisticFailed(data));
    }
  } catch (error: any) {
    yield put(requestFetchUserStatisticFailed(error.response?.data));
  }
}

function* fetchMerchantStatistic(_action: any): any {
  console.log('fetchMerchantStatistic', _action.payload);
  
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_MERCHANT_STATISTIC, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put(requestFetchMerchantStatisticSuccess(data.data));
    } else {
      yield put(requestFetchMerchantStatisticFailed(data));
    }
  } catch (error: any) {
    yield put(requestFetchMerchantStatisticFailed(error.response?.data));
  }
}

function* fetchPostStatistic(_action: any): any {
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_POST_STATISTIC, _action.payload);
    });
    const { data } = response;

    if (data?.code === 200) {
      yield put(requestFetchPostStatisticSuccess(data.data));
    } else {
      yield put(requestFetchPostStatisticFailed(data));
    }
  } catch (error: any) {
    yield put(requestFetchPostStatisticFailed(error.response?.data));
  }
}

export default function* DashboardSaga() {
  yield takeLatest("REQUEST_FETCH_STATISTIC", fetchStatistic);
  yield takeLatest("REQUEST_FETCH_TOTAL_STATISTIC", fetchTotalStatistic);
  yield takeLatest("REQUEST_FETCH_CHART_STATISTIC", fetchChartStatistic);
  yield takeLatest("REQUEST_FETCH_USER_STATISTIC", fetchUserStatistic);
  yield takeLatest("REQUEST_FETCH_MERCHANT_STATISTIC", fetchMerchantStatistic);
  yield takeLatest("REQUEST_FETCH_POST_STATISTIC", fetchPostStatistic);
}
