import DataSet from "core/components/DataSet";
import UserPostTabRowTemplate from "./UserPostTabRowTemplate";
import UserChatTabRowTemplate from "./UserChatTabRowTemplate";
import StatisticTab from "./StatisticTab";

type UserChatTabProps = {
  queryParams: any;
  dataStatistic: any
};
let reduxFuncName = 'userChatTabMgmt'
const UserChatTab = ({queryParams, dataStatistic}: UserChatTabProps) => {
  return (
    <div className="row">
      <StatisticTab data={dataStatistic} queryParams={queryParams}/>
      <div className="col-10">
        <DataSet
          idKey="hidden"
          filterName={reduxFuncName}
          module={"Pawn"}
          columnKey={reduxFuncName}
          pagination={{
            page: queryParams.get("page") || 1,
            page_size: queryParams.get("page_size") || 20,
          }}
          columnTemplate={[
            { label: "Post ID", key: "mc_username", state: true, sort: false },
            { label: "Image", key: "mc_phone", state: true, sort: false },
            { label: "Title", key: "shop_name", state: true, sort: false },
            {
              label: "Address",
              key: "biz_lcnc_path",
              state: true,
              sort: false,
            },
            { label: "Price", key: "shop_addr_1", state: true, sort: false },
            {
              label: "Price min",
              key: "mc_created_at",
              state: true,
              sort: false,
            },
            {
              label: "Negotiable Price",
              key: "mc_state",
              state: true,
              sort: false,
            },
            { label: "Total bid", key: "mc_state", state: true, sort: false },
            { label: "Status", key: "mc_state", state: true, sort: false },
            { label: "Created at", key: "mc_state", state: true, sort: false },
          ]}
          rowTemplate={UserChatTabRowTemplate}
        />
      </div>
    </div>
  );
};

export default UserChatTab;
