import DashboardLayout from "core/layouts/DashboardLayout"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchPawnDataList} from "../../store/actions";
import {parseFilterSelect, setParamsUrl, useCustomNavigate} from "core/utils/helper";

import {API_PULK_PN_LIST,} from "../../api";

import "modules/Pawn/assets/scss/coupon.scss"
import BulkPNCampaignRowTemplate from "./BulkPNCampaignRowTemplate";
import BulkPNCampaignFilter from "./BulkPNCampaignFilter";
import {RouterName} from "core/router";
import {useQuery} from "core/hooks/useQuery";

let reduxFuncName = 'bulkPnMgmt'
const BulkPNPage = (props: any) => {
    const navigate = useCustomNavigate()
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])
    const queryParams = useQuery()
    const [queryParamsState, setQueryParamsState] = useState<any>({
        type: queryParams.get("type") || "",
        keyword: queryParams.get("keyword") || "",
        dateFrom: queryParams.get("dateFrom") || "",
        dateTo: queryParams.get("dateTo") || "",
        relations: 'createdBy',
        sortBy: 'created_at',
        sortDir: 'desc',
        page: queryParams.get("page") || "",
        page_size: queryParams.get("page_size") || ""
    })

    const parseFilter = () => {
        console.log(35, queryParamsState)
        return queryParamsState;
    }

    const setFilter = () => {
        const filter: any = {
            type: parseFilterSelect(filterRedux?.type),
            keyword: filterRedux?.keyword,
            dateFrom: filterRedux?.date?.startDate,
            dateTo: filterRedux?.date?.endDate,
            relations: 'createdBy',
            sortBy: 'created_at',
            sortDir: 'desc',
            page: paginateRedux?.page,
            page_size: paginateRedux?.page_size
        }
        setQueryParamsState(filter)
        setParamsUrl(filter)
        return filter;
    }

    const getDefaultFilterValue = () => {
        return {
            type: queryParams.get("type") || "",
            keyword: queryParams.get("keyword"),
            date: {
                startDate: queryParams.get("dateFrom"),
                endDate: queryParams.get("dateTo")
            }
        }
    }

    const callFetchData = () => {
        console.log(parseFilter(), 'parseFilter()');
        
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_PULK_PN_LIST, {
            ...parseFilter(),
            type: parseFilter().type === 'ALL' ? '' : parseFilter().type
        }))
    }

    const callFetchStatisticData = async() => {
        try{

        } catch (e) {
            console.error(e)
        }
    }

    const callFetchCampaignList = async() => {
        try{

        } catch (e) {
            console.error(e)
        }
    }

    const callDeleteData = async (ids: any) => {

    }

    const handleChangeFilter =(data: any) => {
        setFilter()
    }

    const handleBulkDelete = (ids: any) => {
        callDeleteData(ids)
    }

    const handleCreateCampaign = () => {
        navigate(RouterName.MKT_BULK_PN_CREATE)
    }

    const handleDetail = (item: any) => {
        navigate(RouterName.MKT_BULK_PN_DETAIL.replace(/:id/g, item.id))
    }

    useEffect(() => {
        if(paginateRedux) {
            if(paginateRedux.page != queryParamsState.page || paginateRedux.page_size != queryParamsState.page_size){
                setFilter()
            }
        }
    }, [paginateRedux]);

    useEffect(() => {
        callFetchData()
        callFetchStatisticData()
        callFetchCampaignList()
    }, []);

    useEffect(() => {
        callFetchData()
    }, [queryParamsState]);

    return <>
        <DashboardLayout currentPage="dashboard/marketing/bulk-pn" title="Bulk Push Notification">
            <h1>BULK PUSH NOTIFICATION</h1>
            <BulkPNCampaignFilter
                filterName={reduxFuncName}
                onSubmit={handleChangeFilter}
                onCreate={handleCreateCampaign}
                initialValues={getDefaultFilterValue()}
            />

            <DataSet
                idKey="hidden"
                filterName={reduxFuncName}
                module={'Pawn'}
                columnKey={reduxFuncName}
                pagination={{page: queryParams.get("page") || 1, page_size: queryParams.get("page_size") || 20}}
                columnTemplate={[
                    {label: "Created at", key: "created_at", state: true, sort: false},
                    {label: "Type", key: "type", state: true, sort: false},
                    {label: "Campaign name", key: "campaign_name", state: true, sort: false},
                    {label: "Total target", key: "total_target", state: true, sort: false},
                    {label: "Sent", key: "sent", state: true, sort: false},
                    {label: "Success", key: "success", state: true, sort: false},
                    {label: "Failure", key: "failure", state: true, sort: false},
                    {label: "Created by", key: "created_by", state: true, sort: false},
                ]}
                onEdit={handleDetail}
                onDelete={handleBulkDelete}
                rowTemplate={BulkPNCampaignRowTemplate}
            />

        </DashboardLayout>
    </>
}

export default BulkPNPage
