import {createBrowserRouter} from "react-router-dom";
import PrivateRouter from "./components/System/PrivateRouter";
import PublicRouter from "./components/System/PublicRouter";
import { config } from "./config/app";
import NotFound from "./components/NotFound";
import Gateway from "./components/Gateway";


let routers = [
  {
    name: "INDEX",
    path: '/',
    element: <Gateway/>
  },
  {
    name: "E404",
    path: "*",
    element: <NotFound />,
  },
];

let routerName = {};

const getAllModules = () => {
  let routerModules = require.context('./../modules/', true, /router.tsx$/);
  let modules = require.context('./../modules/', true, /module.ts$/);
	return {routerModules, modules}
}

const dynamicModules = getAllModules();

dynamicModules.modules.keys().forEach((mpath: any) => {
  const moduleJson = dynamicModules.modules(mpath).default;
  
  if (moduleJson?.status?.router) {
    const key = mpath.toString().replace("module.ts", "router.tsx");
    const router = dynamicModules.routerModules(key);
    const rs = router.default || router;
    rs.forEach((r: any) => {
      routerName = { ...routerName, [r.name]: r.path };
      
      let el = r.auth ? (
        <PrivateRouter>{r.element}</PrivateRouter>
      ) : (
        <PublicRouter>{r.element}</PublicRouter>
      );
      routers = [
        ...routers,
        {
          name: r.name,
          element: el,
          path: `${r.path}`,
        },
      ];
    });
  }
});

export const RouterName: any = routerName;

export const Router = routers;

export const AppRouter = createBrowserRouter(Router, {
  basename: ``,
});

export function route(routeName: any, ...rest: any) {
  if (!routeName) {
    return '';
  }
  rest.map((value: any) => {
    routeName = routeName.replace(/:[a-z]*/, value);
  });

  return routeName;
}
