import {useEffect} from "react";
import moment from "moment";
import {formatDateTime, highlightKeywords, numberFormat, useCustomNavigate} from "../../../../core/utils/helper";
import {RouterName} from "../../../../core/router";
import {useSelector} from "react-redux";

const CouponHistoryListRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    const filterRedux = useSelector((state: any) => state.form['couponReconciliationMgmt']?.values)
    // //console.log(filterRedux)
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderCell = (key: any) => {
        switch (key) {
            case 'merchant': return <>{props.data.merchant?.name}<br /><span style={{whiteSpace: "nowrap"}}> - {props.data.merchant?.phone}</span></>
            case 'user': return <>{props.data.user?.display_name}<br /><span style={{whiteSpace: "nowrap"}}> - {props.data.user?.phone}</span></>
            case 'property': return <>[#{props.data.property?.id}] {props.data.property?.name}</>
            case 'amount': return <>{numberFormat(props.data.coupon?.discount_amount)} VND</>
            case 'state': return props.data.settlement_at ?
                <span className="badge rounded-pill bg-warning">SETTLED</span> : <></>
            case 'settlement_by':
                return <>{props.data?.settlement_by?.username}</>
            case 'approved_at':
            case 'settlement_at':
            case 'updated_at':
                return <>{formatDateTime(props.data[key])}</>
            default:
                return <><div dangerouslySetInnerHTML={{__html: highlightKeywords(props.data[key], filterRedux?.keyword)}}></div></>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        props.onEdit(props.data.mc_id)
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        <td width={150}>
            <label>
                <input type={"checkbox"} value={props.data.id} checked={props.checked} className={props.checked ? 'idCheck checked': 'idCheck'} onChange={props.onCheck}/> #{props.data.id}
            </label>
        </td>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
        <td className="text-end">
            <div className="actions">
                {/*<a onClick={handlePreview}><i className="fa-solid fa-eye"></i> <span>Xem</span></a>*/}
                {/*<a onClick={handleEdit}><i className="fa-solid fa-pen-to-square"></i> <span>Edit</span></a>*/}
                {/*<a onClick={handleDelete}><i className="fa-solid fa-trash-can"></i> <span>Re</span></a>*/}
            </div>
        </td>
    </tr>
}
export default CouponHistoryListRowTemplate
