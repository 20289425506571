import KDialog from "../../../../core/components/Dialog/KDialog";

const CouponReconciliationBulkAction = (props: any) => {
    const handleSettlement = () => {
        //console.log(props.ids)
        if(props.onBulkAction) props.onBulkAction({action: 'SETTLEMENT', ids: props.ids})
    }
    return <>
        <button onClick={handleSettlement} className={'btn btn-sm btn-success'}>Settlement</button>

    </>
}

export default CouponReconciliationBulkAction