const FormSection = (props: any) => {
    const renderLeftRight = () => {
        return <div className="form-section">
            <div className="left">
                <h4>{props.sectionHeading}</h4>
                <p>{props.sectionDesc}</p>
            </div>
            <div className="right">
                {props.children}
            </div>
        </div>
    }

    const renderSummaryDetail = () => {
        return <details>
                <summary>{props.sectionHeading}</summary>
                <p>{props.sectionDesc}</p>
                {props.children}
        </details>
    }

    switch (props.type) {
        case 'summary-detail': return renderSummaryDetail();
        default: return renderLeftRight()
    }
}

export default FormSection