import DataSet from "core/components/DataSet";
import { formNameMerchantStatistic } from "../hooks/useStatistics";
import module from "../module";
import MerchantStatisticListRowTemplate from "./MerchantStatisticListRowTemplate";

type MerchantStatisticTableProps = {
  queryParams: any;
  merchantStatistic: any
}
const MerchantStatisticTable = ({queryParams, merchantStatistic}: MerchantStatisticTableProps) => {
  
  return (
    <>
      <div className="row pt-2 mt-4">
        <div className="col-12">
          <strong className="fs-5">Merchant statistics ({merchantStatistic?.total > 0 ? merchantStatistic?.total : 0})</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <DataSet
            idKey={"hidden"}
            filterName={formNameMerchantStatistic}
            module={module.name}
            columnKey={formNameMerchantStatistic}
            pagination={{page: queryParams.get(`m_page`) || 1, page_size: queryParams.get(`m_page_size`) || 10}}
            columnTemplate={[
              { label: "ID", key: "id", state: true, sort: false },
              { label: "Phone", key: "phone", state: true, sort: false },
              {
                label: "Merchant Name",
                key: "shop_name",
                state: true,
                sort: false,
              },
              {
                label: "Address",
                key: "map_address",
                state: true,
                sort: false,
              },
              {
                label: "SignUp at",
                key: "created_at",
                state: true,
                sort: false,
              },
              {
                label: "Approved at",
                key: "approved_at",
                state: true,
                sort: false,
              },
            ]}
            rowTemplate={MerchantStatisticListRowTemplate}
          />
        </div>
      </div>
    </>
  );
};

export default MerchantStatisticTable;
