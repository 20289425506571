import DataSet from "core/components/DataSet"
import DashboardLayout from "core/layouts/DashboardLayout"
import { useBackNavigate, useCustomNavigate } from "core/utils/helper"
import { RouterName } from "core/router"
import API from "core/networks/API"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {useDispatch, useSelector} from "react-redux"
import {SubmissionError, updateSyncErrors} from "redux-form";
import MerchantForm from "./MerchantForm";
import {useParams} from "react-router-dom";
import {API_ADMIN_MERCHANT} from "../../api";
import SwitchButton from "../../../../core/components/SwitchButton";
import Select from "react-select";

const initialValues = {
    title: "",
    slug: "",
    template: 'none',
    content: "",
    seo_title: "",
    seo_description: "",
    seo_keyword: "",
    seo_image: "",
    cover_image: "",
    featured_image: "",
    published_at: "20/12/2024 08:00"
}

const MerchantDetail = (props: any) => {
    const commonRedux = useSelector((state: any) => state.Common)
    // const navigate = useCustomNavigate()
    const navigate = useBackNavigate()

    const dispatch = useDispatch()
    const params = useParams()

    const [formLoading, setFormLoading] = useState(false)
    const [detailItem, setDetailItem] = useState<any>({})
    const handleGoBack = () => {
        navigate()
    }

    const callMerchantDetailAPI = async () => {
        try{
            const res = await API.get(API_ADMIN_MERCHANT+'/'+params.id, {
                'relation[]': [
                    'merchant',
                    'images',
                    'regionCountry',
                    'regionCity',
                    'regionDistrict',
                    'regionWard'
                ]
            })

            if(res.data.data) setDetailItem(res.data.data)
        } catch (e) {
            //console.log(e)
        }
    }

    // const handleSubmit = async (formData: any) => {
    //     //console.log("CREAGE PAGE DATA", formData)
    //     setFormLoading(true)
    //     const error = await callCreatePageAPI(parseData(formData));
    //     //console.log(error)
    //     if(!error){
    //         setFormLoading(false)
    //         toast.success("Thêm trang thành công.")
    //         navigate(RouterName.CONTENT_PAGE)
    //     }else{
    //         setFormLoading(false)
    //         toast.error("Thêm trang thất bại, vui lòng check các lỗi.")
    //         throw new SubmissionError({...error})
    //     }
    // }
    //
    // const parseData = (formData: any) => {
    //     let data = formData;
    //     let template_content = {}
    //     if(commonRedux.setting?.templates){
    //         const [template] = commonRedux.setting.templates.filter((item: any) => item.id == formData.template)
    //         if(template){
    //             //console.log(48, template)
    //             if(template.fields.length > 0){
    //                 for(const field of template.fields){
    //                     if(formData['template_content_'+field.id]){
    //                         template_content = {...template_content, [field.id]: data['template_content_'+field.id]}
    //                         delete data['template_content_'+field.id]
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     data = {...data, template_content}
    //     return data;
    // }
    //
    // const parseValidateError = (errors: any) => {
    //     let result = {}
    //     errors.forEach((item: any) => {
    //         result = {...result, [item.field]: item.error}
    //     })
    //     return result;
    // }

    // const callCreatePageAPI = (data: any) => {
    //     return new Promise(async (resolve, reject) => {
    //         try{
    //             const res = await API.post('/cms/v1/content/page', data)
    //             resolve(false)
    //         } catch(e: any){
    //             if(e.response?.data?.errors.length > 0) {
    //                 resolve(parseValidateError(e.response?.data?.errors))
    //             }
    //         }
    //     })
    //
    // }

    const handleChangeMerchantState = async (e: any) => {
        try{
            const res = await API.put(API_ADMIN_MERCHANT+'/'+params.id,{
                state: e.value
            })
            //console.log(res)
            toast.success("Merchant state updated successfully!")
        } catch (e: any) {
            //console.log(e)
            toast.error("Merchant state update failed!")
        }
    }

    useEffect(()=>{
        // toast.success("Thêm trang thành công.")
        callMerchantDetailAPI()
    }, [])

    return <>
        <DashboardLayout currentPage="dashboard/merchants" title="Merchant management">
            <h1><span className="go-back" onClick={handleGoBack}><i
                className="fa-solid fa-chevron-left"></i> Quay lại</span> Merchant detail</h1>
            <div className="card mt-4 mb-4">
                <div className="card-body">
                    <h4>Merchant information</h4>
                    <table className="table table-bordered table-form">
                        <tr>
                            <th style={{width: '15%'}}>ID</th>
                            <td style={{width: '30%'}}>{detailItem?.merchant?.username}</td>
                            <th style={{width: '15%'}}>State</th>
                            <td style={{width: '30%', padding: '0'}}>
                                {detailItem.state &&
                                <Select
                                    closeMenuOnSelect={true}
                                    options={[
                                        {value: 'ACTIVED', label: 'Active'},
                                        {value: 'INACTIVED', label: 'Inactive'},
                                    ]}
                                    defaultValue={{value: detailItem.state, label: detailItem.state === 'INACTIVED' ? 'Inactive': 'Active'}}
                                    onChange={handleChangeMerchantState}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <th>Merchant Name</th>
                            <td>{detailItem?.merchant?.name}</td>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Merchant Phone</th>
                            <td>{detailItem?.merchant?.phone}</td>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                    <br/>
                    <h4>Store information</h4>
                    <table className="table table-bordered table-form">
                        <tr>
                            <th style={{width: '15%'}}>Store Name</th>
                            <td style={{width: '30%'}}>{detailItem?.name}</td>
                            <th style={{width: '15%'}}></th>
                            <td style={{width: '30%'}}></td>
                        </tr>
                        <tr>
                            <th>Store Address</th>
                            <td>
                                {detailItem.address_1 ? <>
                                    {detailItem?.address_1},&nbsp;
                                </> : null
                                }{detailItem?.map_address}
                            </td>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Business Licence</th>
                            <td></td>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Store photos</th>
                            <td></td>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>

        </DashboardLayout>
    </>
}

export default MerchantDetail
