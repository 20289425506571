import { useEffect, useState } from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { change } from "redux-form";

const format = "DD-MM-YYYY HH:mm";

const DateTimePickerComponent = (props: any) => {
    // const [DTvalue, setDTvalue] = useState(new Date());

    const DTonChange = (stat: any) => {
        // setDTvalue(stat);
        //console.log("date time select value" + stat);
        props.handleDispatch(change(props.formName, props.input.name, moment(stat).format(format)))
    };

    useEffect(()=>{
        //console.log(17, props, moment(props.input.value, format).toDate(), new Date())
    }, [props])

    return <>
        <div className={"custom-input"}>
            <label>{props.label} {props.required && <span className="required">*</span>}</label>
            <DateTimePicker
                format={"d-MM-yyyy HH:mm"}
                onChange={DTonChange}
                disabled={props.disabled}
                value={moment(props.input.value || moment().format(format), format).toDate()}
                clearIcon={null}
            />
            
            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default DateTimePickerComponent