import KeywordComponent from "core/components/KeywordComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import { useCustomNavigate } from "core/utils/helper";
import { updatePaginate } from "core/store/actions";
import {FormEvent, useEffect} from "react";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import DateRangePicker from "core/components/DateRangePicker";
import DateRangePicker2 from "core/components/DateRangePicker2";
import { formName } from './../hooks/useConversation'

interface CustomProps {
    filterName: string,
}

const ConversationFilter: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = (props: any) => {
    const navigate = useCustomNavigate()
    // //console.log(props)
    const { submitting, dispatch } = props

    const handleFilterSubmit = (e: any) => {
        e.preventDefault()
    }

    return <form onSubmit={handleFilterSubmit} className="form-filter row">
        <div className="col-12 m-0 mb-2">
            <Field
                name="date"
                type="hidden"
                disabled={submitting}
                component={DateRangePicker2}
                label="Filter Date"
                handleDispatch={dispatch}
                formName={formName}
            />
        </div>
        <div className="col-4 m-0">
            <Field
                name="query_field"
                type="hidden"
                dataSet={[
                    { value: '', label: 'All' },
                    { value: 'user_name', label: 'User Name' },
                    { value: 'user_phone', label: 'User Phone' },
                    { value: 'shop_name', label: 'Merchant Name' },
                    { value: 'merchant_phone', label: 'Merchant Phone' },
                ]}
                defaultValue={{ value: '', label: 'All' }}
                isMulti={false}
                disabled={submitting}
                component={SelectComponent}
                label="Filter by"
                handleDispatch={dispatch}
                formName={formName}
            />
        </div>
        <div className="col-8 m-0">
            <Field
                name="query_value"
                type="hidden"
                disabled={submitting}
                component={KeywordComponent}
                label="Keywords"
                handleDispatch={dispatch}
                formName={formName}
                minCharacter={1}
            />
        </div>
    </form>
}


export default reduxForm<{}, CustomProps>({
    form: formName
})(ConversationFilter);
