export interface Data {
    id: number;
    name: string;
    description: string;
}

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export const REQUEST_GLOBAL_SETTING = "REQUEST_GLOBAL_SETTING"
export const REQUEST_GLOBAL_SETTING_SUCCESS = "REQUEST_GLOBAL_SETTING_SUCCESS"
export const REQUEST_GLOBAL_SETTING_FAIL = "REQUEST_GLOBAL_SETTING_FAIL"
export const SET_DEBUG_MODE = "SET_DEBUG_MODE"
export const SET_DEBUG_LOG = "SET_DEBUG_LOG"
export const UPLOADING = "UPLOADING"
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS"
export const UPGRADE_REQUIRED = "UPGRADE_REQUIRED"
export const NATIVE_READY = "NATIVE_READY"
export const SET_DATASET_COLUMNS = "SET_DATASET_COLUMNS"
export const UPDATE_DATASET_COLUMNS = "UPDATE_DATASET_COLUMNS"

export const SET_PAGINATE = "SET_PAGINATE"
export const UPDATE_PAGINATE = "UPDATE_PAGINATE"