import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  formatDateTime,
  getAreaInfo,
  useCustomNavigate,
} from "../../../core/utils/helper";
import { RouterName } from "core/router";

const MerchantStatisticListRowTemplate = (props: any) => {
  const navigate = useCustomNavigate();
  const filterRedux = useSelector(
    (state: any) => state.form[props.filterName]?.values
  );
  //console.log(filterRedux)
  useEffect(() => {}, []);

  useEffect(() => {}, [props]);

  const renderCell = (key: any) => {
    switch (key) {
      case "id":
        return <>#{props.data.id}</>;
      case "phone":
        return <>{props.data.phone}</>;
      case "shop_name":
        return <>{props.data.shop_name}</>;
      case "map_address":
        return (
          <>
            {props.data?.address_1 ? `${props.data?.address_1}, ` : ""}{" "}
            {props.data?.map_address
              ? props.data?.map_address
              : getAreaInfo(
                  undefined,
                  props.data?.city_id,
                  props.data?.district_id,
                  props.data?.ward_id
                )}
            {/* {props.data?.address_1 ? `${props.data.address_1}, ` : ''} {props.data.map_address} */}
          </>
        );
      case "created_at":
        return <>{formatDateTime(props.data.created_at)}</>;
      case "approved_at":
        return <>{formatDateTime(props.data.approved_at)}</>;
      default:
        return <></>;
    }
  };

  const handlePreview = () => {
    window.open(`/${props.data?.slug?.alias}.html`, "_blank");
  };
  const handleEdit = () => {
    navigate(RouterName.MERCHANT_DETAIL.replace(/:id/g, props.data.shop_id));
  };
  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete([props.data.id]);
    }
  };

  return (
    <tr onDoubleClick={handleEdit}>
      {props.columns.length > 0 &&
        props.columns.map((col: any, k: number) => {
          if (col.state) return <td key={k}>{renderCell(col.key)}</td>;
        })}
    </tr>
  );
};
export default MerchantStatisticListRowTemplate;
