import {formatDateTime, highlightKeywords, useCustomNavigate} from "../../../../core/utils/helper";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const BulkPNDeviceRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);


    const renderLangName = (id: any) => {
        switch (id) {
            case 1: return "Tiếng Việt";
            case 2: return "English";
            case 3: return "Korean";
            case 4: return "Myanmar";
            case 5: return "Thailand";
            default: return "Unknown";
        }
    }

    const renderStatus = (status: string) => {
        switch (status) {
            case "SUCCESS" : return <span className="text-success">Success</span>;
            default: return <span className="text-danger">Failure</span>;
        }
    }

    const renderCell = (key: any) => {
        switch (key) {
            case "created_at": return <>{formatDateTime(props.data.created_at)}</>;
            case "app_type": return <>{props.data.push_history?.client_type}</>;
            case "status": return <>{renderStatus(props.data.status)}</>;
            case "lang": return <>{renderLangName(props.data.lang_id)}</>;
            case "acc_name": return <>{props.data.push_history?.client_type == "MERCHANT"
                    ? props.data.push_history?.client?.username
                    : props.data.push_history?.client?.display_name
                }</>;
            default:
                return <>{props.data[key]}</>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        props.onEdit(props.data)
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        {props.idKey !== 'hidden' &&
        <td width={150}>
            <label>
                <input type={"checkbox"} value={props.data.id} checked={props.checked} onChange={props.onCheck}/> #{props.data.id}
            </label>
        </td>}
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
        <td className="text-end">
            <div className="actions">
                {/*<a onClick={handlePreview}><i className="fa-solid fa-eye"></i> <span>Xem</span></a>*/}
                {/*<a onClick={handleEdit}><i className="fa-solid fa-pen-to-square"></i> <span>Edit</span></a>*/}
                {/*<a onClick={handleDelete}><i className="fa-solid fa-trash-can"></i> <span>Re</span></a>*/}
            </div>
        </td>
    </tr>
}

export default BulkPNDeviceRowTemplate;
