export interface DataState {
  statisticsMgmt: any;
  userStatisticMgmt: any;
  merchantStatisticMgmt: any;
  postStatisticMgmt: any;
  totalStatisticsMgmt: any;
  chartStatisticsMgmt: any
}

const initialState: DataState = {
  statisticsMgmt: {
    data: [],
    loading: false,
    error: null,
    type: "",
  },
  totalStatisticsMgmt: {
    data: [],
    loading: false,
    error: null,
    type: "",
  },
  chartStatisticsMgmt: {
    data: [],
    loading: false,
    error: null,
    type: "",
  },
  userStatisticMgmt: {
    data: [],
    loading: false,
    error: null,
    type: "",
  },
  merchantStatisticMgmt: {
    data: [],
    loading: false,
    error: null,
    type: "",
  },
  postStatisticMgmt: {
    data: [],
    loading: false,
    error: null,
    type: "",
  },
};

const DashboardReducer = (
  state: DataState = initialState,
  action: any
): DataState => {
  switch (action.type) {
    case "REQUEST_FETCH_STATISTIC":
      return {
        ...state,
        statisticsMgmt: {
          ...state.statisticsMgmt,
          error: null,
          type: action.type,
          loading: true,
        },
      };
    case "REQUEST_FETCH_STATISTIC_SUCCESS":
      return {
        ...state,
        statisticsMgmt: {
          data: action.payload,
          loading: false,
          error: null,
          type: action.type,
        },
      };

    case "REQUEST_FETCH_STATISTIC_FAILED":
      return {
        ...state,
        statisticsMgmt: {
          ...state.statisticsMgmt,
          type: action.type,
          error: action.payload,
          loading: true,
        },
      };
    //
    case "REQUEST_FETCH_TOTAL_STATISTIC":
      return {
        ...state,
        totalStatisticsMgmt: {
          ...state.totalStatisticsMgmt,
          error: null,
          type: action.type,
          loading: true,
        },
      };
    case "REQUEST_FETCH_TOTAL_STATISTIC_SUCCESS":
      return {
        ...state,
        totalStatisticsMgmt: {
          data: action.payload,
          loading: false,
          error: null,
          type: action.type,
        },
      };

    case "REQUEST_FETCH_TOTAL_STATISTIC_FAILED":
      return {
        ...state,
        totalStatisticsMgmt: {
          ...state.totalStatisticsMgmt,
          loading: false,
          error: action.payload,
          type: action.type,
        },
      };
          //
    case "REQUEST_FETCH_CHART_STATISTIC":
      return {
        ...state,
        chartStatisticsMgmt: {
          ...state.chartStatisticsMgmt,
          error: null,
          type: action.type,
          loading: true,
        },
      };
    case "REQUEST_FETCH_CHART_STATISTIC_SUCCESS":
      return {
        ...state,
        chartStatisticsMgmt: {
          data: action.payload,
          loading: false,
          error: null,
          type: action.type,
        },
      };

    case "REQUEST_FETCH_CHART_STATISTIC_FAILED":
      return {
        ...state,
        chartStatisticsMgmt: {
          ...state.chartStatisticsMgmt,
          loading: false,
          error: action.payload,
          type: action.type,
        },
      };
    //
    case "REQUEST_FETCH_USER_STATISTIC":
      return {
        ...state,
        userStatisticMgmt: {
          ...state.userStatisticMgmt,
          error: null,
          type: action.type,
          loading: true,
        },
      };
    case "REQUEST_FETCH_USER_STATISTIC_SUCCESS":
      return {
        ...state,
        userStatisticMgmt: {
          ...action.payload,
          loading: false,
          error: null,
          type: action.type,
        },
      };

    case "REQUEST_FETCH_USER_STATISTIC_FAILED":
      return {
        ...state,
        userStatisticMgmt: {
          ...state.userStatisticMgmt,
          error: action.payload,
          type: action.type,
          loading: true,
        },
      };
    //
    case "REQUEST_FETCH_MERCHANT_STATISTIC":
      return {
        ...state,
        merchantStatisticMgmt: {
          ...state.merchantStatisticMgmt,
          error: null,
          type: action.type,
          loading: true,
        },
      };
    case "REQUEST_FETCH_MERCHANT_STATISTIC_SUCCESS":
      return {
        ...state,
        merchantStatisticMgmt: {
          ...action.payload,
          loading: false,
          error: null,
          type: action.type,
        },
      };

    case "REQUEST_FETCH_MERCHANT_STATISTIC_FAILED":
      return {
        ...state,
        merchantStatisticMgmt: {
          ...state.merchantStatisticMgmt,
          error: action.payload,
          type: action.type,
          loading: true,
        },
      };
    //
    case "REQUEST_FETCH_POST_STATISTIC":
      return {
        ...state,
        postStatisticMgmt: {
          ...state.postStatisticMgmt,
          error: null,
          type: action.type,
          loading: true,
        },
      };
    case "REQUEST_FETCH_POST_STATISTIC_SUCCESS":
      return {
        ...state,
        postStatisticMgmt: {
          ...action.payload,
          loading: false,
          error: null,
          type: action.type,
        },
      };

    case "REQUEST_FETCH_POST_STATISTIC_FAILED":
      return {
        ...state,
        postStatisticMgmt: {
          ...state.postStatisticMgmt,
          error: action.payload,
          type: action.type,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default DashboardReducer;
