import { Action } from 'redux';
import {
    Data,
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    NATIVE_READY,
    REQUEST_GLOBAL_SETTING,
    REQUEST_GLOBAL_SETTING_FAIL,
    REQUEST_GLOBAL_SETTING_SUCCESS,
    SET_DATASET_COLUMNS,
    SET_DEBUG_LOG,
    SET_DEBUG_MODE, SET_PAGINATE, UPDATE_DATASET_COLUMNS, UPDATE_PAGINATE,
    UPGRADE_REQUIRED,
    UPLOAD_SUCCESS,
    UPLOADING
} from './types';
import * as url from "url";



export interface FetchDataAction extends Action<typeof FETCH_DATA> {}

export interface FetchDataSuccessAction extends Action<typeof FETCH_DATA_SUCCESS> {
    payload: Data[];
}

export interface FetchDataErrorAction extends Action<typeof FETCH_DATA_ERROR> {
    payload: Error;
}

export const fetchData = (): FetchDataAction => ({
    type: FETCH_DATA,
});

export const fetchDataSuccess = (data: Data[]): FetchDataSuccessAction => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataError = (error: Error): FetchDataErrorAction => ({
    type: FETCH_DATA_ERROR,
    payload: error,
});

export const requestGlobalSetting = () => ({
    type: REQUEST_GLOBAL_SETTING
})

export const requestGlobalSettingSuccess = (payload: any) => ({
    type: REQUEST_GLOBAL_SETTING_SUCCESS,
    payload
})

export const requestGlobalSettingFail = (payload: any) => ({
    type: REQUEST_GLOBAL_SETTING_FAIL,
    payload
})


export const setDebugMode = () => ({
    type: SET_DEBUG_MODE
})

export const setDebugLog = (context: any) => ({
    type: SET_DEBUG_LOG,
    payload: context
})

export const setUploading = () => ({
    type: UPLOADING
})

export const setUploadSuccess = () => ({
    type: UPLOAD_SUCCESS
})

export const requestUpgrade = (url: string) => ({
    type: UPGRADE_REQUIRED,
    payload: url
})

export const setNativeReady = (payload: any = {}) => ({
    type: NATIVE_READY,
    payload
})

export const setDataSetColumns = (storeName:string, payload: any = {}) => ({
    type: SET_DATASET_COLUMNS,
    storeName,
    payload
})

export const updateDataSetColumns = (payload: any = {}) => ({
    type: UPDATE_DATASET_COLUMNS,
    payload
})


export const setPaginate = (storeName: string, payload: any = {page: 1, page_size: 20}) => ({
    type: SET_PAGINATE,
    payload: {storeName, payload}
})

export const updatePaginate = (storeName: string, payload: any) => ({
    type: UPDATE_PAGINATE,
    payload: {storeName, payload}
})