import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { OPTION_CHART, formNameStatistics } from "../hooks/useStatistics";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import SelectComponent from "core/components/SelectComponent";
interface CustomProps {
  chartData: any;
  queryParams: any;
  statisticParam: any;
}

type DataItem = {
  day: string;
  total: number;
};

type DataMonthItem = {
  month: string;
  total: number;
};


function removeDuplicatesAndSortDay(array: DataItem[]): DataItem[] {
  const uniqueDays = array.reduce<{ [key: string]: DataItem }>((acc, current) => {
      acc[current.day] = current;
      return acc;
  }, {});

  const result = Object.values(uniqueDays);

  return result.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());
}

function removeDuplicatesAndSortMonth(array: DataMonthItem[]): DataMonthItem[] {
  const uniqueMonths = array.reduce<{ [key: string]: DataMonthItem }>((acc, current) => {
      acc[current.month] = current;
      return acc;
  }, {});

  const result = Object.values(uniqueMonths);

  return result.sort((a, b) => new Date(a.month).getMonth() - new Date(b.month).getMonth());
}


const getTotalsForDates = (datesArray: DataItem[], uniqueDates: any): { day: string, total: number }[] => {
  return uniqueDates.map((date: any) => {
    const foundItem = datesArray.find(item => item.day === date.day);
    return foundItem ? foundItem.total : 0;
  });
}


const getTotalsForMonths = (datesArray: DataMonthItem[], uniqueMonths: string[]): any => {
  return uniqueMonths.map((date: any) => {
    const foundItem = datesArray.find(item => item.month === date.month);
    return foundItem ? foundItem.total : 0;
  });
}

const getCategoriesForType = (merchants: any, users: any, properties: any, option: any) => {
  if (merchants || users || properties) {
    const _dataArr = [...merchants, ...users, ...properties];
    if (option === 'month') {
      return removeDuplicatesAndSortMonth(_dataArr)
    }else{
      return removeDuplicatesAndSortDay(_dataArr)
    }
  }
}

const StatisticChart: React.FC<
  CustomProps & InjectedFormProps<{}, CustomProps>
> = (props: any) => {
  const { submitting, dispatch } = props;

  const { merchants, users, properties } = props.chartData?.data;

  const _categories: any = getCategoriesForType(merchants, users, properties, props?.statisticParam?.chart_option);


  const getSeries = (data: any) => {
    if (props.statisticParam.chart_option === 'day') {
      if (data) {
        return getTotalsForDates(data, _categories)
      }else{
        return [];
      }
    }
    if (props.statisticParam.chart_option === 'month') {
      if (data) {
        return getTotalsForMonths(data, _categories)
      }else{
        return [];
      }
    }
  }

  const renderSeries = () => {
    const _series = [
      {
        name: "Users",
        data: getSeries(users),
      },
      {
        name: "Merchants",
        data: getSeries(merchants),
      },
      {
        name: "Posts",
        data: getSeries(properties),
      },
    ];
    return _series;
  };

  const renderCategories = () => {
    if (merchants || users || properties) {
      const _month = _categories.map(
        (item: any) => item[props.statisticParam.chart_option]
      );
      return _month;
    }
    return [];
  };

  useEffect(() => {
    setDataChart((p: any) => ({
      ...p,
      series: renderSeries(),
      options: {
        ...p.option,
        xaxis: {
          categories: renderCategories(),
        },
      },
    }));
  }, [merchants, users, properties]);

  const [dataChart, setDataChart] = useState({
    series: renderSeries(),
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          colors: {
            ranges: [{
              from: 0,
              to: 0,
              color: '#FFFFFF00' // màu trắng với độ trong suốt hoàn toàn để ẩn cột
            }]
          },
        },
      },
      dataLabels: {
        enabled: false, //show number in column
        
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: renderCategories(),
      },
      yaxis: {
        min: 0,
        // max: 1,
        title: {
          text: "",
        },
        decimalsInFloat: 0,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
    } as any,
  });

  const handleChartSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleChartSubmit} className="form-filter row">
      <div className="col-12 m-0 mb-2 p-3 bg-white">
        <div className="row flex-end">
          <div className="col-4"></div>
          <div className="col-4"></div>
          <div className="col-4">
            <Field
              className="select-chart-custom"
              name="chart_option"
              type="hidden"
              dataSet={OPTION_CHART}
              defaultValue={OPTION_CHART[0]}
              isMulti={false}
              disabled={submitting}
              component={SelectComponent}
              label=""
              handleDispatch={dispatch}
              formName={formNameStatistics}
            />
          </div>
        </div>
        <div id="chart">
          <ReactApexChart
            options={dataChart.options}
            series={dataChart.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </form>
  );
};

export default reduxForm<{}, CustomProps>({
  form: formNameStatistics,
})(StatisticChart);
