export interface DataState {
    data: any;
    loading: boolean;
    error: Error | null;
}

const initialState: DataState = {
    data: [],
    loading: false,
    error: null,
};

const DashboardReducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    switch (action.type) {

        default:
            return state;
    }
};

export default DashboardReducer