import DefaultImage from 'core/assets/images/default-image.png'
import ImageComponent from './ImageComponent'

const SEOPreview = (props: any) => {
    return props.title ? <>
        <div className="seo-preview"  style={{backgroundColor: '#FFF', padding: '1rem'}}>
            <div className="og-image">
                <ImageComponent src={props.image} alt="" />
            </div>
            <div className="og-link">{props.url}</div>
            <div className="og-title">{props.title}</div>
            <div className="og-description">{props.description}</div>
    </div>
    </>: <></>
}

export default SEOPreview