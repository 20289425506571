import {FETCH_CONTENT_LIST, FETCH_CONTENT_LIST_FAIL, FETCH_CONTENT_LIST_SUCCESS, INIT_CONTENT_LIST} from "./types";

export default {}

export const initPawnDataList = (storeName:string) => ({
    type: INIT_CONTENT_LIST,
    storeName
})

export const fetchPawnDataList = (storeName:string, method: string, url:string, payload: any) => ({
    type: FETCH_CONTENT_LIST,
    storeName, method, url, payload
})

export const fetchPawnDataListSuccess = (storeName:string, payload: any) => ({
    type: FETCH_CONTENT_LIST_SUCCESS,
    storeName,
    payload
})

export const fetchPawnDataListFail = (storeName:string) => ({
    type: FETCH_CONTENT_LIST_FAIL,
    storeName
})