import DateRangePicker2 from "core/components/DateRangePicker2";
import { useCustomNavigate } from "core/utils/helper";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { formNameStatistics } from "../hooks/useStatistics";

interface CustomProps {
}

const StatisticFilter: React.FC<
  CustomProps & InjectedFormProps<{}, CustomProps>
> = (props: any) => {
  const navigate = useCustomNavigate();
  console.log(props)
  const { submitting, dispatch } = props;

  const handleFilterSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleFilterSubmit} className="form-filter row">
      <div className="col-8 m-0 mb-2">
        <div className="row mb-4">
          <div className="col-12">
            <strong className="fs-5">Filter date</strong>
          </div>
        </div>
        <Field
          name="date"
          type="hidden"
          disabled={submitting}
          component={DateRangePicker2}
          label=""
          handleDispatch={dispatch}
          formName={formNameStatistics}
        />
      </div>
    </form>
  );
};

export default reduxForm<{}, CustomProps>({
  form: formNameStatistics,
})(StatisticFilter);
