import { useEffect } from "react"
import { change } from "redux-form";

const TextAreaComponent = (props: any) => {

    const handleChange = (e: any) => {
        // //console.log(e.target.value)
        props.handleDispatch(change(props.formName, props.input.name, e.target.value))
    }

    useEffect(()=>{
        // //console.log(5, props)
    }, [props])

    return <>
        <div className={"custom-input"}>
            <label>{props.label} {props.required && <span className="required">*</span>}</label>
            <div className={"input-wrapper"}>
                <textarea onChange={handleChange}
                          disabled={props.disabled}
                          readOnly={props.readOnly}
                          placeholder={props.placeholder}
                          rows={props.rows || 5}
                          value={props.input.value} />
            </div>

            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default TextAreaComponent