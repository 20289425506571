import KeywordComponent from "core/components/KeywordComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import { useCustomNavigate } from "core/utils/helper";
import { updatePaginate } from "core/store/actions";
import {FormEvent, useEffect} from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import DateRangePicker from "core/components/DateRangePicker";
import DateRangePicker2 from "core/components/DateRangePicker2";

let formName = 'bulkPnMgmt'
interface CustomProps {
    onCreate: Function,
    filterName: string,
}
const Filter: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = (props: any) => {
    const navigate = useCustomNavigate()
    // //console.log(props)
    const { error, handleSubmit, pristine, reset, submitting, dispatch } = props

    //console.log(props.filterName, 'props');
    
    const handleFilterSubmit = (e: any) => {
        e.preventDefault()
    }

    const handleSearch = (e: any) => {
        dispatch(updatePaginate(props.filterName, {
            page: 1,
        }))
        handleSubmit((values: any) => {
            props.onSubmit(values);
        })();
    }

    const handleGoToCreate = () => {
        props.onCreate()
    }

    useEffect(() => {
        //console.log(41, props.initialValues)
        // dispatch(updatePaginate(props.filterName, {
        //     page_size: parseInt(props.initialValues.page_size || 20),
        //     page: parseInt(props.initialValues.page || 1)
        // }))
    }, []);

    return <form onSubmit={handleFilterSubmit} className="form-filter row">
        <div className="col-4 m-0">
            <Field
                name="date"
                type="hidden"
                disabled={submitting}
                component={DateRangePicker2}
                label="Filter Date"
                handleDispatch={dispatch}
                formName={formName}
            />
        </div>
        <div className="col-2 m-0">
            <Field
                name="keyword"
                type="hidden"
                disabled={submitting}
                component={KeywordComponent}
                label="Keywords"
                handleDispatch={dispatch}
                formName={formName}
            />
        </div>
        <div className="col-2 m-0">
            <Field
                name="type"
                type="hidden"
                dataSet={[
                    { value: 'ALL', label: 'All' },
                    { value: 'EVENT', label: 'Event' },
                    { value: 'UPDATE', label: 'Update Notify' },
                    { value: 'OTHER', label: 'Other' },
                ]}
                // defaultValue={{ value: '', label: 'All' }}
                isMulti={false}
                disabled={submitting}
                component={SelectComponent}
                label="Push type"
                handleDispatch={dispatch}
                formName={formName}
            />
        </div>
        <div className="col-4 m-0">

            {/*<Field name="page" type="hidden" component="input"/>*/}
            <div className="d-flex flex-column h-100">
                <div className="mt-auto mb-1">
                    <button type="button" className="btn btn-search me-2" onClick={handleSearch}>
                        <i className="fa-solid fa-magnifying-glass"></i> Search
                    </button>
                    <button type="button" className="btn btn-create" onClick={handleGoToCreate}>
                        <i className="fa-solid fa-plus"></i> Create Campaign
                    </button>
                </div>
            </div>
        </div>
    </form>
}


export default reduxForm<{}, CustomProps>({
    form: formName
})(Filter);
