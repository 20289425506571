import { config } from "core/config/app";
import SettingPage from "./pages/SettingPage";

const router =  [
    {
        name: "SETTING_PAGE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/settings`,
        element: <SettingPage />
    },
]
export default router
